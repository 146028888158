import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {controllingRoute, settingsRoute} from '@octaved/node-details/src/LargeView/RoutePaths';
import {lazy} from 'react';
import {RouteObject} from 'react-router';
import {Navigate} from 'react-router-dom';
import {dialogRouteObjects} from '../Dialogs';
import {
  advancedRoute,
  billingsRoute,
  boardRoute,
  customerRoute,
  editTaskRoute,
  generalRoute,
  guestUserRoute,
  historyRoute,
  milestonesRoute,
  moreRoute,
  offerSumsRoute,
  planningRoute,
  quotasRoute,
  responsibilityRoute,
  statusRoute,
  tasksRoute,
  timeAndMoneyRoute,
  timeTrackingRoute,
} from './InspectorRoutes';
import ProjectControllingDemoModeWrapper from './ProjectControllingDemoModeWrapper';
import {getWrappedStandardInspectorRoutes} from './StandardInspector';

const More = lazy(() => import('../../../Drawer/StandardInspector/Views/More'));
const Settings = lazy(() => import('../../../Drawer/StandardInspector/Views/Settings'));
const GuestUser = lazy(() => import('../../../Drawer/StandardInspector/Views/GuestUser'));
const Advanced = lazy(() => import('../../../Drawer/StandardInspector/Views/Advanced'));
const Board = lazy(() => import('../../../Pages/Projects/Inspector/Common/Board/Board'));
const Billings = lazy(() => import('../../../Drawer/StandardInspector/Views/Billings'));
const General = lazy(() => import('../../../Drawer/StandardInspector/Views/General'));
const Status = lazy(() => import('../../../Drawer/StandardInspector/Views/Status'));
const TimeTracking = lazy(() => import('../../../Drawer/StandardInspector/Views/TimeTracking'));
const Planning = lazy(() => import('../../../Drawer/StandardInspector/Views/Planning'));
const Milestones = lazy(() => import('../../../Drawer/StandardInspector/Views/Milestones'));
const Responsibility = lazy(() => import('../../../Drawer/StandardInspector/Views/Responsibility'));
const TimeAndMoney = lazy(() => import('../../../Drawer/StandardInspector/Views/TimeAndMoney'));
const EditTask = lazy(() => import('../../../Drawer/Task/EditTask'));
const QuotasTab = lazy(() => import('../../../Pages/Projects/Inspector/Common/QuotasTab'));
const QuotasTabDemoMode = lazy(() => import('@octaved/demo-mode').then((module) => ({default: module.QuotasTab})));
const Tasks = lazy(() => import('../../../Pages/Projects/Inspector/Common/Tasks/Tasks'));
const TaskHistory = lazy(() => import('../../../Pages/Projects/Inspector/Common/TaskHistory/TaskHistory'));
const OfferSums = lazy(() => import('../../../Pages/CreateOffer/Inspector/OfferSums/OfferSums'));
const SelectedCustomer = lazy(() => import('../../../Pages/Projects/Inspector/Common/Customer/SelectedCustomer'));

export function subInspectorWrapper(children: RouteObject[], subChildren: RouteObject[], path: string): RouteObject[] {
  const subChildRoute: RouteObject = {path, children: subChildren};

  return children.map((child) => {
    const childRouts: RouteObject[] = child.children || [];
    return {
      ...child,
      children: [...childRouts, subChildRoute],
    } as RouteObject;
  });
}

export const historyRouteObject = (): RouteObject => ({
  children: dialogRouteObjects,
  element: <TaskHistory />,
  path: historyRoute,
});
export const advancedRouteObject = (): RouteObject => ({
  children: dialogRouteObjects,
  element: <Advanced />,
  path: advancedRoute,
});
export const moreRouteObject = (): RouteObject => ({
  children: dialogRouteObjects,
  element: <More />,
  path: moreRoute,
});
export const guestUserRouteObject = (): RouteObject => ({
  children: dialogRouteObjects,
  element: <GuestUser />,
  path: guestUserRoute,
});
export const guestUserNewInspectorRouteObject = (): RouteObject => ({
  children: dialogRouteObjects,
  element: <GuestUser />,
  path: 'guests',
});
export const billingsRouteObject = (): RouteObject => ({
  children: dialogRouteObjects,
  element: <Billings />,
  path: billingsRoute,
});

export const boardRouteObject = (): RouteObject => ({
  children: dialogRouteObjects,
  element: <Board />,
  path: boardRoute,
});
export const generalRouteObject = (): RouteObject => ({
  children: dialogRouteObjects,
  element: <General />,
  path: `${generalRoute}/*`,
});
export const statusRouteObject = (): RouteObject => ({
  children: dialogRouteObjects,
  element: <Status />,
  path: `${statusRoute}/*`,
});
export const controllingRouteObject = (): RouteObject => ({
  children: dialogRouteObjects,
  element: <Status />,
  path: `${controllingRoute}/*`,
});
export const timeTrackingRouteObject = (): RouteObject => ({
  children: dialogRouteObjects,
  element: <TimeTracking />,
  path: `${timeTrackingRoute}/*`,
});
export const responsibilityRouteObject = (): RouteObject => ({
  children: dialogRouteObjects,
  element: <Responsibility />,
  path: responsibilityRoute,
});
export const offerSumsRouteObject = (): RouteObject => ({
  children: dialogRouteObjects,
  element: <OfferSums />,
  path: offerSumsRoute,
});
export const planningRouteObject = (): RouteObject => ({
  children: dialogRouteObjects,
  element: <Planning />,
  path: planningRoute,
});
export const milestonesRouteObject = (): RouteObject => ({
  children: dialogRouteObjects,
  element: <Milestones />,
  path: milestonesRoute,
});
export const settingsRouteObject = (): RouteObject => ({
  children: dialogRouteObjects,
  element: <Settings />,
  path: settingsRoute,
});
export const editTaskRouteObject = (): RouteObject => ({
  children: dialogRouteObjects,
  element: <EditTask />,
  path: editTaskRoute,
});

export const tasksRouteObject = (): RouteObject => ({
  children: getWrappedStandardInspectorRoutes({
    children: [
      editTaskRouteObject(),
      planningRouteObject(),
      historyRouteObject(),
      {
        element: <Navigate to={editTaskRoute} replace />,
        path: '*',
      },
    ],
    parameterName: 'inspectL2Id',
    standardInspectorProps: {
      tabConfig: {
        [EnumFlowNodeType.VALUE_TASK]: [
          {
            path: editTaskRoute,
            token: 'pages:projects.inspector.menu.general',
          },
          {
            //the task planning inspector also shows the logical dependencies, which are visible with basic read:
            access: () => true,
            path: planningRoute,
            token: 'pages:projects.inspector.menu.planning',
          },
          {
            path: historyRoute,
            token: 'pages:projects.inspector.menu.history',
          },
        ],
      },
    },
  }),
  element: <Tasks />,
  path: tasksRoute,
});
export const timeAndMoneyRouteObject = (): RouteObject => ({
  children: dialogRouteObjects,
  element: <TimeAndMoney />,
  path: timeAndMoneyRoute,
});

export const quotasRouteObject = (): RouteObject => ({
  children: dialogRouteObjects,
  element: (
    <ProjectControllingDemoModeWrapper demoModePage={<QuotasTabDemoMode />}>
      <QuotasTab />
    </ProjectControllingDemoModeWrapper>
  ),
  path: quotasRoute,
});
export const customerRouteObject = (): RouteObject => ({
  children: dialogRouteObjects,
  element: <SelectedCustomer />,
  path: customerRoute,
});
