import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {toChildren} from '@octaved/node-search/src/Factories/Tree';
import {useMinMaxPlanningDateInSubtree} from '@octaved/planning/src/Modules/MinMaxPlanningDateInSubtrees';
import Spacer from '@octaved/ui-components/src/React/Spacer';
import {ReactElement, useRef} from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Loader} from 'semantic-ui-react';
import DialogAndDrawerHeader from '../../Components/Layout/DialogAndDrawerHeader';
import {Pid} from '../../EntityInterfaces/Pid';
import {useLoadedNodes, usePid} from '../../Modules/Hooks/Nodes';
import {useCombinedNodeSearch} from '../../Modules/Hooks/NodeSearch';
import {nodeTreeSelector} from '../../Modules/Selectors/NodeTreeSelectors';
import {FlowState} from '../../Modules/State';
import {DialogContent, DialogFrame, DialogTitle} from '../DialogFrame';
import Content from './Content';

const titleTransToken = {
  [EnumFlowNodeType.VALUE_WORK_PACKAGE]: 'dialogs:duplicatePid.titleWorkPackage',
  [EnumFlowNodeType.VALUE_PROJECT]: 'dialogs:duplicatePid.titleProject',
  [EnumFlowNodeType.VALUE_GROUP]: 'dialogs:duplicatePid.titleGroup',
};

export default function DuplicatePid(): ReactElement | null {
  const {pidNodeId = ''} = useParams<'pidNodeId'>();
  const parentNodeId = useSelector((s: FlowState) => nodeTreeSelector(s)[pidNodeId]) || '';
  const pid = usePid(pidNodeId);
  const {hasLoadedOnce: hasLoadedSiblingIds, nodeIds: siblingNodeIds} = useCombinedNodeSearch(
    pid
      ? {
          and: [toChildren(parentNodeId), ['nodeType', pid.nodeType]],
        }
      : null,
  );
  const {hasLoadedOnce: hasLoadedSiblings, nodes: siblings} = useLoadedNodes<Pid>(siblingNodeIds);
  const {hasLoadedOnce: hasLoadedMinMaxPlanning, minMaxPlanning} = useMinMaxPlanningDateInSubtree(pidNodeId);
  const hasLoaded = !!pid && hasLoadedSiblingIds && hasLoadedSiblings && hasLoadedMinMaxPlanning;

  //Keep siblings static during one dialog instance to prevent the UI from updating with new siblings during saving:
  const siblingsRef = useRef<Pid[] | null>(null);
  if (hasLoaded && !siblingsRef.current) {
    siblingsRef.current = siblings;
  }

  return (
    <DialogFrame size={'small'}>
      <DialogTitle>{pid && <DialogAndDrawerHeader noPadding header={titleTransToken[pid.nodeType]} />}</DialogTitle>
      <DialogContent>
        <Loader active={!hasLoaded} />
        {hasLoaded && siblingsRef.current && (
          <Content pid={pid} siblings={siblingsRef.current} minMaxPlanning={minMaxPlanning} />
        )}
        <Spacer spacing={10} />
      </DialogContent>
    </DialogFrame>
  );
}
