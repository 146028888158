import {useInspectorId} from '@octaved/hooks';
import {Uuid} from '@octaved/typescript/src/lib';
import {IconButton} from '@octaved/ui';
import {boolFilter} from '@octaved/utilities';
import {Airplay, ChevronLeft, ChevronRight, Link, MoreHorizontal, PlusCircle, Trash} from 'lucide-react';
import {ReactElement, useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import {useTaskIndentation} from '../../../../Hooks/TaskIndentation';
import {getNodeAncestrySelector} from '../../../../Modules/Selectors/NodeTreeSelectors';
import {FlowState} from '../../../../Modules/State';
import {deleteTask} from '../../../../Modules/Tasks';
import {editTaskRoute} from '../../../../Routing/Routes/Inspectors/InspectorRoutes';
import {generateStandardInspectorRoute} from '../../../../Routing/Routes/Inspectors/StandardInspector';
import ConfirmDialog from '../../../Dialog/ConfirmDialog';
import DropdownPopup from '../../../Form/Dropdown/DropdownPopup';
import {appendDropdownOptions} from '../../../Form/Dropdown/DropdownPopup/AppendOptions';
import {DropdownPopupItemOptions, DropdownPopupItemType} from '../../../Form/Dropdown/DropdownPopup/Types';
import {useStartTimeTrackingOption} from '../../../TimeRecord/StartTimeTrackingOption';
import {useTaskListContext} from '../../TaskListContext';
import {useTaskListReadonlyContext} from '../../TaskListReadonlyContext';
import AddLogicalDependency from './AddLogicalDependency';
import {ShallowTask} from './ShallowTask';

function useOpenInspectorRoute(id: Uuid): () => void {
  const navigate = useNavigate();
  const inspectId = useInspectorId();
  return useCallback(() => {
    if (inspectId !== id) {
      const inspectorRoute = generateStandardInspectorRoute(id, editTaskRoute);

      navigate(inspectorRoute + window.location.search);
    } else {
      navigate('.' + window.location.search, {});
    }
  }, [inspectId, id, navigate]);
}

interface Props {
  depth: number;
  previousSiblingId?: Uuid;
  setShowNewSiblingTask: (show: null | 'prev' | 'next') => void;
  task: ShallowTask;
}

export default function TaskRowSubMenu({
  depth,
  previousSiblingId,
  setShowNewSiblingTask,
  task,
}: Props): ReactElement | null {
  const {readonlyOrNotManageable} = useTaskListReadonlyContext();
  const {showOpenTaskInspector, hideTrackTime} = useTaskListContext();
  const {canToggleIndentation, isIndented, toggleIndentation} = useTaskIndentation(task.id, depth, previousSiblingId);
  const openInspectorRoute = useOpenInspectorRoute(task.id);
  const {workPackage, subWorkPackage} = useSelector((s: FlowState) => getNodeAncestrySelector(s)(task.id));
  const referenceNode = subWorkPackage ?? workPackage;
  const [selectLogicalDepndency, setSelectLogicalDepdency] = useState(false);
  const [showDeleteTaskConfirm, setShowDeleteTaskConfirm] = useState(false);
  const hiddenByOptions = hideTrackTime || readonlyOrNotManageable || !referenceNode || referenceNode.isLocked;
  const timeTrackingOption = useStartTimeTrackingOption(task.name, referenceNode?.id, hiddenByOptions);

  const items = useMemo<DropdownPopupItemOptions[]>(() => {
    const items: DropdownPopupItemOptions[] = [];

    appendDropdownOptions(
      items,
      boolFilter([
        showOpenTaskInspector && {
          icon: <Airplay rotate={'90deg'} />,
          iconColor: 'darkGrey',
          onClick: () =>
            typeof showOpenTaskInspector === 'function' ? showOpenTaskInspector(task.id) : openInspectorRoute(),
          token: 'taskList:rowMenu.openDetails',
          type: DropdownPopupItemType.item,
        },
        canToggleIndentation &&
          !readonlyOrNotManageable && {
            icon: isIndented ? <ChevronLeft /> : <ChevronRight />,
            iconColor: 'darkGrey',
            onClick: toggleIndentation,
            token: isIndented ? 'taskList:rowMenu.decreaseDepth' : 'taskList:rowMenu.increaseDepth',
            type: DropdownPopupItemType.item,
          },
      ]),
    );

    appendDropdownOptions(
      items,
      boolFilter([
        !readonlyOrNotManageable && {
          icon: <PlusCircle />,
          iconColor: 'darkGrey',
          onClick: () => setShowNewSiblingTask('prev'),
          token: 'taskList:rowMenu.addTaskAbove',
          type: DropdownPopupItemType.item,
        },
        !readonlyOrNotManageable && {
          icon: <PlusCircle />,
          iconColor: 'darkGrey',
          onClick: () => setShowNewSiblingTask('next'),
          token: 'taskList:rowMenu.addTaskBelow',
          type: DropdownPopupItemType.item,
        },
      ]),
    );

    appendDropdownOptions(items, boolFilter([!timeTrackingOption.hidden && timeTrackingOption]));

    appendDropdownOptions(
      items,
      boolFilter([
        !readonlyOrNotManageable &&
          !workPackage?.isAutoChainActive && {
            icon: <Link />,
            iconColor: 'darkGrey',
            onClick: () => setSelectLogicalDepdency(true),
            token: 'taskList:rowMenu.createLogicalDependency',
            type: DropdownPopupItemType.item,
          },
      ]),
    );

    appendDropdownOptions(
      items,
      boolFilter([
        !readonlyOrNotManageable && {
          hidden: readonlyOrNotManageable,
          icon: <Trash />,
          iconColor: 'red',
          onClick: () => setShowDeleteTaskConfirm(true),
          token: 'taskList:rowMenu.deleteTask',
          type: DropdownPopupItemType.item,
        },
      ]),
    );

    return items;
  }, [
    showOpenTaskInspector,
    canToggleIndentation,
    readonlyOrNotManageable,
    isIndented,
    toggleIndentation,
    timeTrackingOption,
    workPackage?.isAutoChainActive,
    task.id,
    openInspectorRoute,
    setShowNewSiblingTask,
  ]);

  const dispatch = useDispatch();
  const {t} = useTranslation();

  if (!items.length) {
    return null;
  }

  return (
    <>
      <DropdownPopup items={items} inDrawer closeOnSelect>
        <IconButton variant={'ghost'} icon={<MoreHorizontal />} size={'sm'} />
      </DropdownPopup>
      {selectLogicalDepndency && <AddLogicalDependency task={task} onClose={() => setSelectLogicalDepdency(false)} />}
      {showDeleteTaskConfirm && (
        <ConfirmDialog
          buttonColorScheme={'delete'}
          title={'drawer:task.confirmDeleteHeader'}
          buttonLabel={'general:delete'}
          buttonIcon={<Trash />}
          size={'mini'}
          cancelCallback={() => setShowDeleteTaskConfirm(false)}
          submitCallback={() => {
            if (dispatch(deleteTask(task.id))) {
              setShowDeleteTaskConfirm(false);
            }
            return false;
          }}
        >
          <span>
            {t('drawer:task.confirmDeleteInfo', {
              name: task?.name || '',
            })}
          </span>
        </ConfirmDialog>
      )}
    </>
  );
}
