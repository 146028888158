import IsGranted from '@octaved/security/src/Authorization/IsGranted';
import {Uuid} from '@octaved/typescript/src/lib';
import {IconButton} from '@octaved/ui';
import {Trash} from 'lucide-react';
import {ReactElement, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import ConfirmPopup from '../../Components/Dialog/ConfirmPopup';
import {useTask} from '../../Modules/Hooks/Nodes';
import {deleteTask} from '../../Modules/Tasks';
import {setInspector} from '../../Modules/Ui';
import InspectorButtonFooterGrid from '../InspectorButtonFooterGrid';
import {inspectorContext} from '../InspectorContext/InspectorContext';

interface Props {
  task: Uuid;
}

export default function TaskFooter({task}: Props): ReactElement | null {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const taskEntity = useTask(task)!;
  const inspectorCtx = useContext(inspectorContext);

  return (
    <IsGranted right={'FLOW_NODE_TASK_MANAGE_BASIC'} unitId={task}>
      <InspectorButtonFooterGrid>
        <ConfirmPopup
          afterSubmit={setInspector}
          title={'drawer:task.confirmDeleteHeader'}
          content={
            <span>
              {t('drawer:task.confirmDeleteInfo', {
                name: taskEntity?.name || '',
              })}
            </span>
          }
          buttonColorScheme={'delete'}
          buttonLabel={'general:deleteTask'}
          buttonIcon={<Trash />}
          onConfirm={() => {
            if (dispatch(deleteTask(task))) {
              (inspectorCtx?.back || inspectorCtx?.close)?.();
              return true;
            }
            return false;
          }}
        >
          <IconButton
            colorScheme={'delete'}
            label={'general:deleteTask'}
            size={'sm'}
            icon={<Trash />}
            variant={'outline'}
          />
        </ConfirmPopup>
      </InspectorButtonFooterGrid>
    </IsGranted>
  );
}
