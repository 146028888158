import {nodeTreeSelector} from '@octaved/flow/src/Modules/Selectors/NodeTreeSelectors';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {getAllDescendantsForRootIds} from '@octaved/trees/src/GenericTreeBuilder';
import {Uuid} from '@octaved/typescript/src/lib';
import {currentOrgUserRightsSelector} from '@octaved/users/src/Selectors/CurrentOrgUserSelectors';
import memoize from 'lodash/memoize';
import {useSelector} from 'react-redux';
import {createSelector} from 'reselect';
import {GlobalRight, NodeRight} from './Rights';

export const anyNode = Symbol('anyNode'); // Caution: This also includes personal tasks!
export const anyNodeRight = Symbol('anyNodeRight');

export type AnyRight = GlobalRight | NodeRight | typeof anyNodeRight;

export interface RightsExport {
  globalRights: ReadonlyArray<GlobalRight>;
  nodeRights: {
    nodeIdToRightSetId: Readonly<Record<string, string | undefined>>;
    rightSets: Readonly<Record<string, ReadonlyArray<NodeRight> | undefined>>;
  };
}

interface RightsWithSets {
  globalRights: ReadonlySet<GlobalRight>;
  nodeRights: {
    nodeIdToRightSetId: Readonly<Record<string, string | undefined>>;
    rightSets: Readonly<Record<string, ReadonlySet<NodeRight> | undefined>>;
  };
}

const rightsWithSetsSelector = createSelector(currentOrgUserRightsSelector, (rights): RightsWithSets => {
  return {
    globalRights: new Set(rights.globalRights),
    nodeRights: {
      nodeIdToRightSetId: rights.nodeRights.nodeIdToRightSetId,
      rightSets: Object.fromEntries(
        Object.entries(rights.nodeRights.rightSets).map(([ident, value]) => [ident, new Set(value)]),
      ),
    },
  };
});

export type IsGrantedMatchMode =
  | 'all'
  | 'any'
  | 'allInTree+self'
  | 'anyInTree+self'
  | 'allInTree-self'
  | 'anyInTree-self';

export type IsGrantedNode = Uuid | typeof anyNode | null | undefined;
export type IsGrantedNodes = IsGrantedNode | ReadonlyArray<Uuid>;

export interface IsGranted {
  (right: GlobalRight): boolean;

  (right: NodeRight | typeof anyNodeRight, nodeId: IsGrantedNode): boolean;

  (right: NodeRight | typeof anyNodeRight, nodeId: IsGrantedNodes, matchMode?: IsGrantedMatchMode): boolean;
}

function isGlobalRight(right: AnyRight): right is GlobalRight {
  return typeof right === 'string' && right.startsWith('FLOW_GLOBAL_');
}

function isNodeRight(right: AnyRight): right is NodeRight {
  return typeof right === 'string' && right.startsWith('FLOW_NODE_');
}

export const isGrantedSelector = createSelector(
  rightsWithSetsSelector,
  nodeTreeSelector,
  ({globalRights, nodeRights: {nodeIdToRightSetId, rightSets}}, nodeTree) =>
    memoize<IsGranted>(
      (right: AnyRight, nodeId?: IsGrantedNodes, matchMode: IsGrantedMatchMode = 'all') => {
        if (isGlobalRight(right)) {
          return globalRights.has(right);
        }
        if ((right === anyNodeRight || isNodeRight(right)) && nodeId) {
          if (nodeId === anyNode) {
            const sets = Object.values(rightSets);
            if (right === anyNodeRight) {
              return sets.some((set) => set!.size > 0);
            } else {
              return sets.some((set) => set!.has(right));
            }
          } else {
            let nodeIds: ReadonlyArray<Uuid> = Array.isArray(nodeId) ? nodeId : [nodeId];
            let mode = matchMode;
            if (mode.startsWith('anyInTree')) {
              mode = 'any';
              nodeIds = [...getAllDescendantsForRootIds(nodeTree, nodeIds, mode.endsWith('+self'))];
            }
            if (mode.startsWith('allInTree')) {
              mode = 'all';
              nodeIds = [...getAllDescendantsForRootIds(nodeTree, nodeIds, mode.endsWith('+self'))];
            }
            const filter =
              right === anyNodeRight
                ? (id: Uuid): boolean => (rightSets[nodeIdToRightSetId[id] || '']?.size ?? 0) > 0
                : (id: Uuid): boolean => !!rightSets[nodeIdToRightSetId[id] || '']?.has(right);
            return mode === 'all' ? nodeIds.every(filter) : nodeIds.some(filter);
          }
        }
        return false;
      },
      (...args) => JSON.stringify(args),
    ),
);

export const isGrantedNodeFilterSelector = createSelector(
  rightsWithSetsSelector,
  ({nodeRights: {nodeIdToRightSetId, rightSets}}) =>
    memoize((right: NodeRight): ((id: Uuid) => boolean) => {
      return (id) => !!rightSets[nodeIdToRightSetId[id] || '']?.has(right);
    }),
);

export const useIsGranted = ((...args) => {
  // @ts-ignore args are correct
  return useSelector((s: FlowState) => isGrantedSelector(s)(...args));
}) as IsGranted;
