import {DateTimeStr} from '@octaved/typescript';
import {cn, tableGridCellVariant} from '@octaved/ui';
import {generateUuid} from '@octaved/utilities';
import {noop} from 'lodash';
import {ReactElement, useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {PlanningDatesList} from '../../../EntityInterfaces/PlanningDates';
import {PlanningRealizationNode} from '../../../EntityInterfaces/PlanningRealizationNode';
import {addPlanningDate} from '../../../Modules/PlanningDates';
import DurationSelection from './DurationSelection';
import TimePeriodDatePicker from './TimePeriodDatePicker';

interface EmptyTimePeriodRowProps {
  node: PlanningRealizationNode;
  planningDates: PlanningDatesList;
  reset?(): void;
  forceRelativeToParent: boolean;
}

export default function EmptyTimePeriodRow({
  node,
  planningDates,
  reset,
  forceRelativeToParent,
}: EmptyTimePeriodRowProps): ReactElement {
  const dispatch = useDispatch();
  const saveChange = useCallback(
    (plannedStart: DateTimeStr, plannedEnd: DateTimeStr) => {
      const planningDateId = generateUuid();
      dispatch(addPlanningDate(node.id, planningDateId, plannedStart, plannedEnd, forceRelativeToParent));
      reset?.();
    },
    [dispatch, node.id, reset, forceRelativeToParent],
  );

  return (
    <>
      <div data-testid={'timePeriodRow-new-index'} className={cn(tableGridCellVariant({position: 'left'}))}></div>
      <div data-testid={'timePeriodRow-new-dateSelection'} className={cn(tableGridCellVariant({position: 'left'}))}>
        <TimePeriodDatePicker
          node={node}
          planningDate={null}
          planningDates={planningDates}
          readonly={false}
          saveChange={saveChange}
        />
      </div>
      <div data-testid={'timePeriodRow-new-duration'} className={cn(tableGridCellVariant())}>
        <DurationSelection duration={0} onChange={noop} disabled />
      </div>
      <div data-testid={'timePeriodRow-new-menu'}></div>
    </>
  );
}
