import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {isGrantedSelector} from '@octaved/security/src/Authorization/Authorization';
import {Uuid} from '@octaved/typescript/src/lib';
import {TWButton} from '@octaved/ui';
import {NIL} from '@octaved/utilities';
import {useFormik} from 'formik';
import {ArrowRightCircle, PlusCircle} from 'lucide-react';
import {ReactElement, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useSearchParams} from 'react-router-dom';
import * as Yup from 'yup';
import {NewLabelColors} from '../../Components/Form/ColorPicker/ColorPickerWithPalette';
import {NodeType} from '../../EntityInterfaces/Nodes';
import {useCloseAndSelectNewNode} from '../../Hooks/CloseNodeCreationDialog';
import {CopyPidOptions} from '../../Modules/CopyPid';
import {useLoadNodes, useProject} from '../../Modules/Hooks/Nodes';
import {useCombinedNodeSearch} from '../../Modules/Hooks/NodeSearch';
import {createProject, createProjectEntity} from '../../Modules/Projects';
import {customerEntitiesSelector} from '../../Modules/Selectors/CustomerSelectors';
import {nodeEntitySelector} from '../../Modules/Selectors/NodeSelectors';
import {useNodeAncestry} from '../../Modules/Selectors/NodeTreeSelectors';
import {projectsFilteredCustomerSelector, projectsOpenNodesSetSelector} from '../../Modules/Selectors/UiSelectors';
import {setUiState} from '../../Modules/Ui';
import DetailsForm from './DetailsForm';
import TemplateForm from './TemplateForm';

export interface NewProjectFormik {
  color: string;
  customerId: Uuid;
  customerLocationId: Uuid | null;
  name: string;
  projectFolderId: Uuid;
  templateNodeId: string | null;
}

interface Props {
  changeDialogTitleToken: (titleToken: string) => void;
  initialCustomerId: Uuid | undefined; //defaults to the customer of the relative node or none at all
  initialCustomerLocationId: Uuid | undefined; //defaults to the customer location of the relative node or none at all
  relativeNode: NodeType;
  onClose?: () => void;
}

export default function NewProjectForm({
  changeDialogTitleToken,
  initialCustomerId,
  initialCustomerLocationId,
  relativeNode,
  onClose,
}: Props): ReactElement {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const customers = useSelector(customerEntitiesSelector);
  const filteredCustomer = useSelector(projectsFilteredCustomerSelector);
  const projectsOpenNodes = useSelector(projectsOpenNodesSetSelector);
  const closeAndSelectNewPid = useCloseAndSelectNewNode();
  const [searchParams] = useSearchParams();
  const {nodeIds: templateIds} = useCombinedNodeSearch({and: [['prIsTemplate'], {not: ['isArchived']}]});
  useLoadNodes(templateIds);
  const {projectFolders, project: relativeProject} = useNodeAncestry(relativeNode.id, true);
  const nodes = useSelector(nodeEntitySelector);

  const [copyAssignments, setCopyAssignments] = useState(true);
  const [copyTasks, setCopyTasks] = useState(true);
  const copyOptions: CopyPidOptions = {copyAssignments, copyTasks};

  const [view, setView] = useState<'details' | 'templateDetails'>('details');

  const onFinishAndSelectNewNode = useCallback(
    async (newNodeId: Uuid, newNodeType: EnumFlowNodeType, customerId: Uuid) => {
      const onFinish = searchParams.get('onFinish');
      if (onFinish === 'addToProjectPlanning') {
        dispatch(
          (await import('@octaved/planning/src/Modules/UpdateExpandedTreeNodes')).selectProjectForProjectPlanning(
            newNodeId,
            customerId,
          ),
        );
      }

      const newOpenNodes = new Set(projectsOpenNodes);
      newOpenNodes.add(newNodeId);
      dispatch(
        setUiState({
          pages: {
            projects: {
              //Reset the customer filter:
              filteredCustomer: filteredCustomer !== customerId ? null : filteredCustomer,
              //Open the new project node in the tree:
              openNodes: [...newOpenNodes],
            },
          },
        }),
      );
      closeAndSelectNewPid(newNodeId, newNodeType);
    },
    [closeAndSelectNewPid, dispatch, filteredCustomer, projectsOpenNodes, searchParams],
  );

  const isGranted = useSelector(isGrantedSelector);

  const customerIdFromSearch = searchParams.get('customerId'); //TODO: Remove when the dialog routing is removed

  const formik = useFormik<NewProjectFormik>({
    initialValues: {
      color: NewLabelColors[Math.floor(Math.random() * NewLabelColors.length)].slice(1),
      customerId: initialCustomerId ?? customerIdFromSearch ?? relativeProject?.flowCustomer ?? NIL,
      customerLocationId:
        initialCustomerLocationId ??
        (customerIdFromSearch || initialCustomerId ? null : (relativeProject?.customerLocation ?? null)),
      name: '',
      projectFolderId: projectFolders[0].id,
      templateNodeId: null,
    },
    onSubmit: async (values) => {
      if (values.templateNodeId) {
        changeDialogTitleToken('dialogs:createProject.titleWithTemplateOptions');
        setView('templateDetails');
        return;
      }

      const project = createProjectEntity(values.customerId);
      project.name = values.name;
      project.color = values.color;
      project.customerLocation = values.customerLocationId;

      await dispatch(createProject(project, values.projectFolderId));

      if (onClose) {
        onClose();
      } else {
        await onFinishAndSelectNewNode(project.id, EnumFlowNodeType.VALUE_PROJECT, project.flowCustomer);
      }
    },
    validationSchema: Yup.object().shape({
      customerId: Yup.string().test(
        'notNil',
        'general:pid.validationErrors.flowCustomer',
        (val) => !!val && val !== NIL,
      ),
      customerLocationId: Yup.string()
        .nullable()
        .when(['customerId'], ([customerId], schema) => {
          const locations = customers[customerId || '']?.locations || [];
          return locations.length ? schema.required('dialogs:createProject.customerLocationRequired') : schema;
        }),
      name: Yup.string()
        .min(1, 'general:node.nameEmptyError')
        .max(191, 'general:node.nameTooLongError')
        .required('general:node.nameEmptyError'),
      projectFolderId: Yup.string().test(
        'canCreate',
        'general:pid.validationErrors.cannotCreateProjectInFolder',
        (val) => !!val && isGranted('FLOW_NODE_PID_MANAGE_BASIC', val) && nodes[val]?.isArchived === false,
      ),
    }),
  });
  const templateProject = useProject(formik.values.templateNodeId);

  if (view === 'templateDetails' && templateProject) {
    return (
      <TemplateForm
        color={formik.values.color}
        copyOptions={copyOptions}
        customerId={formik.values.customerId}
        customerLocationId={formik.values.customerLocationId}
        name={formik.values.name}
        onFinish={async (newNodeId) => {
          await onFinishAndSelectNewNode(newNodeId, EnumFlowNodeType.VALUE_PROJECT, formik.values.customerId);
        }}
        projectFolderId={formik.values.projectFolderId}
        setCopyAssignments={setCopyAssignments}
        setCopyTasks={setCopyTasks}
        templateProject={templateProject}
      />
    );
  }

  return (
    <form className={'mb-8 flex flex-col gap-y-6'} onSubmit={formik.handleSubmit}>
      <DetailsForm formik={formik} />

      <div className={'flex justify-center'}>
        <TWButton
          size={'lg'}
          colorScheme={formik.values.templateNodeId ? 'primary' : 'green'}
          isLoading={formik.isSubmitting}
          type={'submit'}
          withIcon
          variant={'solid'}
        >
          {formik.values.templateNodeId ? (
            <ArrowRightCircle className={'size-4'} />
          ) : (
            <PlusCircle className={'size-4'} />
          )}
          {t(formik.values.templateNodeId ? 'general:next' : 'general:create')}
        </TWButton>
      </div>
    </form>
  );
}
