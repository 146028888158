import DaysOrHoursContext from '@octaved/flow/src/Components/DaysOrHoursContext';
import {useTimeTrackingSelector} from '@octaved/flow/src/Modules/Selectors/SettingSelectors';
import {cn, TabButtons, TabButtonsContent, TabButtonsList, TabButtonsTrigger, TWButton} from '@octaved/ui';
import {ReactElement, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import NodeScopeSection from '../../../Components/Fields/Controlling/Section/NodeScopeSection';
import NodeWorkPackagesArchivedSection from '../../../Components/Fields/Controlling/Section/NodeWorkPackagesArchivedSection';
import NodeWorkPackagesOfferSection from '../../../Components/Fields/Controlling/Section/NodeWorkPackagesOfferSection';
import NodeWorkPackagesProgressSection from '../../../Components/Fields/Controlling/Section/NodeWorkPackagesProgressSection';
import NodeWorkPackagesStatusSection from '../../../Components/Fields/Controlling/Section/NodeWorkPackagesStatusSection';
import {NodeTasksSection} from '../../../Components/Fields/General/Section/NodeTasksSection';
import InspectorViewFrame from '../../InspectorViewFrame';
import ControllingTimeTracking from './ControllingTimeTracking';
import {ProjectDetailsProps} from './General';

export default function Controlling({node}: ProjectDetailsProps): ReactElement {
  const {t} = useTranslation();
  const useTimeTracking = useSelector(useTimeTrackingSelector);
  const [view, setView] = useState<'overview' | 'timeTracking'>('overview');
  const [showDays, setShowDays] = useState<boolean>(false);

  return (
    <InspectorViewFrame
      footer={
        view === 'timeTracking' ? (
          <div className={'flex items-center justify-center'}>
            <TWButton variant={'outline'} size={'xs'} onClick={() => setShowDays((s) => !s)}>
              {t(showDays ? 'general:date.valuesInHours' : 'general:date.valuesInDays')}
            </TWButton>
          </div>
        ) : undefined
      }
    >
      <TabButtons value={view} onValueChange={(value: string) => setView(value as 'overview' | 'timeTracking')}>
        {useTimeTracking && (
          <TabButtonsList>
            <TabButtonsTrigger value={'overview'}>Übersicht</TabButtonsTrigger>
            <TabButtonsTrigger value={'timeTracking'}>Zeitbuchungen</TabButtonsTrigger>
          </TabButtonsList>
        )}
        <TabButtonsContent value={'overview'}>
          <div className={cn('flex flex-col justify-between gap-y-10', useTimeTracking && 'pt-4')}>
            <NodeScopeSection node={node} />
            <NodeTasksSection node={node} showReliabilityMeter />
            <NodeWorkPackagesProgressSection nodeId={node.id} />
            <NodeWorkPackagesStatusSection nodeId={node.id} />
            <div className={'flex flex-col gap-y-3'}>
              <NodeWorkPackagesOfferSection nodeId={node.id} />
              <NodeWorkPackagesArchivedSection nodeId={node.id} />
            </div>
          </div>
        </TabButtonsContent>
        {useTimeTracking && (
          <TabButtonsContent value={'timeTracking'}>
            <DaysOrHoursContext selector={() => showDays}>
              <ControllingTimeTracking node={node} />
            </DaysOrHoursContext>
          </TabButtonsContent>
        )}
      </TabButtons>
    </InspectorViewFrame>
  );
}
