import {Slot} from '@radix-ui/react-slot';
import {cva, type VariantProps} from 'class-variance-authority';
import * as React from 'react';
import {cn} from './Hooks';
import {LoadingDots} from './LoadingDots';

//Must set hover for all texts to override <a>-tags's hover:
const twButtonVariants = cva(
  'items-center shrink-0 justify-center relative inline-flex rounded text-sm font-normal ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:select-none disabled:opacity-50 active:scale-[0.96]',
  {
    compoundVariants: [
      //ghost bg's
      {
        className: 'hover:!bg-slate-50',
        colorScheme: 'slate',
        variant: ['outline', 'ghost'],
      },
      {
        className: 'hover:!bg-sky-50',
        colorScheme: 'primary',
        variant: ['outline', 'ghost'],
      },
      {
        className: 'hover:!bg-red-50',
        colorScheme: 'red',
        variant: ['outline', 'ghost'],
      },
      {
        className: 'hover:!bg-green-50',
        colorScheme: 'green',
        variant: ['outline', 'ghost'],
      },
      //solid buttons white text
      {
        className: 'text-white hover:text-white',
        colorScheme: ['green', 'red', 'primary'],
        variant: 'solid',
      },
      //isActive rules
      {
        className: '!border-sky-600 shadow-sky-700/10 shadow-md',
        colorScheme: ['slate'],
        isActive: true,
        variant: ['solid'],
      },
      {
        className: 'text-sky-600 hover:text-sky-600',
        colorScheme: ['slate'],
        isActive: true,
        variant: ['ghost'],
      },
      //iconOnly width rules
      {
        className: 'w-10',
        iconOnly: true,
        size: 'xl',
      },
      {
        className: 'w-9',
        iconOnly: true,
        size: 'lg',
      },
      {
        className: 'w-8',
        iconOnly: true,
        size: 'md',
      },
      {
        className: 'w-7',
        iconOnly: true,
        size: 'sm',
      },
      {
        className: 'w-6',
        iconOnly: true,
        size: 'xs',
      },
      //show border for slate buttons
      {
        className: 'border',
        colorScheme: 'slate',
        variant: ['solid', 'outline'],
      },
    ],
    defaultVariants: {
      colorScheme: 'slate',
      isActive: false,
      size: 'md',
      variant: 'solid',
    },
    variants: {
      colorScheme: {
        green: 'bg-flow-green text-flow-green border-flow-green hover:bg-emerald-700 hover:text-emerald-700',
        primary: 'bg-flow-primary border-flow-primary text-flow-primary hover:text-sky-800 hover:bg-sky-800',
        red: 'bg-flow-red text-flow-red border-flow-red hover:bg-red-700 hover:text-red-700',
        slate: 'border-slate-300 bg-slate-100 text-slate-700 hover:bg-slate-200 hover:text-slate-800',
      },
      iconOnly: {
        false: '',
        true: '',
      },
      isActive: {
        false: '',
        true: '',
      },
      size: {
        lg: 'h-9 px-3',
        md: 'h-8 px-2',
        sm: 'h-7 px-2',
        xl: 'h-10 px-4 py-1',
        xs: 'h-6 px-2',
      },
      variant: {
        ghost: 'border-none bg-transparent hover:bg-transparent',
        outline: 'border bg-transparent hover:bg-transparent',
        solid: '',
      },
    },
  },
);

interface TWButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof twButtonVariants> {
  asChild?: boolean;
  innerClassName?: string;
  isLoading?: boolean;
  withIcon?: boolean;
}

const TWButton = React.forwardRef<HTMLButtonElement, TWButtonProps>(
  (
    {
      className,
      variant,
      size,
      children,
      colorScheme,
      asChild = false,
      withIcon,
      innerClassName,
      isActive = false,
      iconOnly = false,
      isLoading = false,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(twButtonVariants({colorScheme, isActive, size, variant, iconOnly}), className)}
        ref={ref}
        {...props}
      >
        <div
          className={cn(
            'inline-flex h-full items-center justify-center transition-opacity duration-100',
            isLoading && 'opacity-0',
            withIcon && 'gap-x-2',
            innerClassName,
          )}
        >
          {children}
        </div>
        {typeof isLoading === 'boolean' && (
          <div
            className={cn(
              'pointer-events-none absolute inset-0 flex items-center justify-center opacity-0 transition-opacity duration-100',
              isLoading && 'opacity-100',
            )}
          >
            <LoadingDots />
          </div>
        )}

        {isActive && variant === 'ghost' && (
          <div className={'absolute bottom-0 left-0 right-0 h-0.5 rounded bg-sky-600'} />
        )}
      </Comp>
    );
  },
);
TWButton.displayName = 'TWButton';

export {TWButton, twButtonVariants};

export type {TWButtonProps};
