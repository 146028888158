import DropdownPopup from '@octaved/flow/src/Components/Form/Dropdown/DropdownPopup';
import NodeName from '@octaved/flow/src/Components/Node/NodeName';
import NodeTypeName from '@octaved/flow/src/Components/Node/NodeTypeName';
import StringPath from '@octaved/flow/src/Components/Node/StringPath';
import {useInspectorSubMenu} from '@octaved/flow/src/Drawer/StandardInspector/InspectorSubMenu';
import {Group, Project, WorkPackage} from '@octaved/flow/src/EntityInterfaces/Pid';
import {SubWorkPackage} from '@octaved/flow/src/EntityInterfaces/SubWorkPackage';
import {Task} from '@octaved/flow/src/EntityInterfaces/Task';
import {EllipsisTooltip, IconButton, TWButton} from '@octaved/ui';
import {ArrowLeft, MoreHorizontal} from 'lucide-react';
import {ReactElement, ReactNode, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {Link, useSearchParams} from 'react-router-dom';
import NodeTypeInfoBar, {useNodeInfoBars} from '../../InspectorView/Components/NodeTypeInfoBar';
import {Header} from './Header';
import ProjectTreeSelector from './ProjectTreeSelector/ProjectTreeSelector';

export const createUrl = (pathname: string, params: URLSearchParams): string => {
  const paramsString = params.toString();
  const queryString = `${paramsString.length ? '?' : ''}${paramsString}`;

  return `${pathname}${queryString}`;
};

export function useLastPage(): {lastPage: string | null; extendLastPage: (url: string) => string} {
  const [searchParams] = useSearchParams();
  const lastPage = searchParams.get('lastPage') || null;

  return {
    lastPage,
    extendLastPage: useCallback(
      (url: string) => {
        const [pathname, searchParams] = url.split('?');
        const urlSearch = new URLSearchParams(searchParams);
        if (lastPage) {
          urlSearch.set('lastPage', lastPage);
        }
        return createUrl(pathname, urlSearch);
      },
      [lastPage],
    ),
  };
}

interface NodeHeaderProps {
  additionalContent?: ReactNode;
  node: Project | Group | Task | WorkPackage | SubWorkPackage;
}

export function NodeHeader({additionalContent, node}: NodeHeaderProps): ReactElement {
  const {t} = useTranslation();
  const menuOptions = useInspectorSubMenu(node);
  const {lastPage} = useLastPage();
  const nodeHasInfoBars = useNodeInfoBars(node);
  return (
    <>
      <Header
        hasBorder={!nodeHasInfoBars.length}
        title={
          <div className={'flex flex-col items-center'}>
            <div className={'flex items-center'}>
              <div className={'mb-0 text-base font-semibold text-slate-500'}>
                <NodeTypeName type={node.nodeType} nodeId={node.id} />:
              </div>
              &nbsp;
              <EllipsisTooltip
                ellipsisText={<NodeName nodeId={node.id} notSearchable />}
                tooltipContent={t('tooltips:general.expandEllipsis')}
                className={'truncate'}
              />
              &nbsp;
              <DropdownPopup items={menuOptions} closeOnSelect position={'bottom right'}>
                <IconButton variant={'ghost'} icon={<MoreHorizontal />} size={'sm'} />
              </DropdownPopup>
            </div>
            <div className={'text-sm text-slate-500'}>
              <StringPath nodeId={node.id} excludeSelf includeCustomer />
            </div>
          </div>
        }
        leftControls={
          <div className={'flex items-center gap-x-2'}>
            {lastPage && (
              <Link to={lastPage}>
                <TWButton withIcon size={'sm'} colorScheme={'slate'} variant={'outline'}>
                  <ArrowLeft className={'h-4 w-4'} />
                  {t('general:back')}
                </TWButton>
              </Link>
            )}
            <ProjectTreeSelector nodeId={node.id} />
            {additionalContent}
          </div>
        }
      />

      <NodeTypeInfoBar bars={nodeHasInfoBars} />
    </>
  );
}
