import {EnumFlowNodeType, EnumFlowTaskStatus} from '@octaved/env/src/dbalEnumTypes';
import {FilterState} from '@octaved/flow/src/EntityInterfaces/Filter/FilterState';
import {PlanningFilterStates} from '@octaved/flow/src/EntityInterfaces/Filter/PlanningFilters';
import {NodeSearchCondition, NodeSearchIdentWithoutValue} from '@octaved/flow/src/EntityInterfaces/NodeSearch';
import {userProjectRoleIdsQueryGenerator} from '@octaved/flow/src/Modules/Filter/AssignedProjectRoleId';
import {createBooleanNodeTypeSearchQueryGenerator} from '@octaved/flow/src/Modules/Filter/BooleanSearch';
import {doFilterStatesDeviateFromDefaults} from '@octaved/flow/src/Modules/Filter/CompareFilterStates';
import {mergeFilterStatesWithDefaults} from '@octaved/flow/src/Modules/Filter/MergeFilterStatesWithDefaults';
import {createStringsSearchQueryGenerator} from '@octaved/flow/src/Modules/Filter/StringsSearch';
import {
  getIsResponsible,
  getNodeSearchQueryResultsSelector,
} from '@octaved/flow/src/Modules/Selectors/NodeSearchSelectors';
import {
  rootFoldersMaterialResourceFolderSelector,
  rootFoldersProjectFolderSelector,
} from '@octaved/flow/src/Modules/Selectors/RootFolderSelectors';
import {todayIsoDateSelector} from '@octaved/flow/src/Today';
import {emptyNodeSearchResult, withAncestors, withDescendants} from '@octaved/node-search/src/Factories/Tree';
import {mergeStates} from '@octaved/store/src/MergeStates';
import {MaybeUuid, Uuid} from '@octaved/typescript/src/lib';
import {fromIsoFormat, toIsoFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import {currentOrgUserSettingsSelector} from '@octaved/users/src/Selectors/CurrentOrgUserSelectors';
import {boolFilter} from '@octaved/utilities';
import {memoize} from 'lodash';
import {createSelector} from 'reselect';
import {defaultPlanningFilterStates, PlanningPages} from '../Modules/Filters/PlanningDefaultFilters';
import {
  BarTextDisplayment,
  BaselineComparison,
  BaselineComparisonNodeSetting,
  DragMode,
  MaterialResourceFilter,
  PlanningUiState,
  ProjectFilter,
  SimulationExcecutionLogEntry,
  WorkloadDisplayment,
} from '../Modules/Ui';
import {
  getEffectiveSwpsNotPlanned,
  getEffectiveTasksNotPlanned,
  getEffectiveWpsNotPlanned,
  swpTasks,
  wpTasks,
} from '../NodeSearchPlanningFactories';
import {PlanningState} from '../PlanningState';

export const taskPlanningSelector = (state: PlanningState): PlanningUiState => state.ui.pages.planning;
export const teamPlanningCollapsedWorkPackagesSelector = (state: PlanningState): Uuid[] =>
  state.ui.pages.planning.teamPlanningCollapsedWorkPackages;

export const planningSubMenuCollapsedSelector = (state: PlanningState): boolean =>
  state.ui.pages.planning.collapsedSubMenu;
export const availableProjectsForPlaningConditions: NodeSearchCondition = {
  and: [['nodeType', EnumFlowNodeType.VALUE_PROJECT], {not: ['isArchived']}],
};
export const storedProjectsForPlaningSelector = createSelector(
  taskPlanningSelector,
  (ui): readonly Uuid[] | null => ui.projectPlanningSelectedProjects,
);
export const projectPlanningSelectedProjectsSelector = createSelector(
  taskPlanningSelector,
  getNodeSearchQueryResultsSelector,
  (ui, getNodeSearchQueryResults): readonly Uuid[] => {
    const stored = ui.projectPlanningSelectedProjects;
    const available = getNodeSearchQueryResults(availableProjectsForPlaningConditions);
    if (!stored) {
      return available;
    }
    return stored.filter((id) => available.includes(id));
  },
);
export const projectPlanningExtendedNodesSelector = (state: PlanningState): Record<Uuid, boolean> | null =>
  state.ui.pages.planning.projectPlanningExtendedNodes;
export const materialResourcesExtendedNodesSelector = (state: PlanningState): Record<Uuid, boolean> | null =>
  state.ui.pages.planning.materialResourcesExtendedNodes;
export const projectPlanningShowMaterialResourcesSelector = (state: PlanningState): boolean =>
  state.ui.pages.planning.projectPlanningShowMaterialResources;
export const projectPlanningGroupByCustomerSelector = (state: PlanningState): boolean =>
  state.ui.pages.planning.groupProjectPlanningByCustomer;
export const visibleGanttColumnsSelector = (state: PlanningState): string[] =>
  state.ui.pages.planning.visibleGanttColumns;
export const visibleMaterialResourceColumnsSelector = (state: PlanningState): string[] =>
  state.ui.pages.planning.visibleMaterialResourceColumns;
export const projectFilterSelector = (state: PlanningState): ProjectFilter => state.ui.pages.planning.projectFilter;
export const materialResourceFilterSelector = (state: PlanningState): MaterialResourceFilter =>
  state.ui.pages.planning.materialResourceFilter;
export const teamFilterSelector = (state: PlanningState): ProjectFilter => state.ui.pages.planning.teamFilter;
export const teamPlanningDragModeSelector = (state: PlanningState): DragMode =>
  state.ui.pages.planning.teamPlanningDragMode;
export const teamPlanningShowCompletedTasksSelector = (state: PlanningState): boolean =>
  state.ui.pages.planning.teamPlanningShowCompletedTasks;
export const teamPlanningShowWorkloadSelector = (state: PlanningState): boolean =>
  state.ui.pages.planning.teamPlanningShowWorkload;
export const teamPlanningShowTasksSelector = (state: PlanningState): boolean =>
  state.ui.pages.planning.teamPlanningShowTasks;
export const teamPlanningShowTasksWithoutOwnPlanningSelector = (state: PlanningState): boolean =>
  state.ui.pages.planning.teamPlanningShowTasksWithoutOwnPlanning;
export const teamPlanningWorkloadDisplaymentSelector = (state: PlanningState): WorkloadDisplayment =>
  state.ui.pages.planning.teamPlanningWorkloadDisplayment;
export const myPlanningWorkloadDisplaymentSelector = (state: PlanningState): WorkloadDisplayment =>
  state.ui.pages.planning.myPlanningWorkloadDisplayment;
const baselineComparisonSelector = (state: PlanningState): BaselineComparison =>
  state.ui.pages.planning.baselineComparison;
export const baselineComparisonColumnWidthSelector = (state: PlanningState): number =>
  state.ui.pages.planning.baselineComparison.columnWidth;
export const baselineComparisonSearchSelector = (state: PlanningState): string =>
  state.ui.pages.planning.baselineComparison.search;
export const baselineComparisonShowTasksSelector = (state: PlanningState): boolean =>
  state.ui.pages.planning.baselineComparison.showTasks;
export const baselineComparisonBarTextDisplaymentSelector = (state: PlanningState): BarTextDisplayment =>
  state.ui.pages.planning.baselineComparison.barTextDisplayment;
export const simulationModeActiveSelector = (state: PlanningState): boolean =>
  state.ui.pages.planning.simulationModeActive;
export const selectedSimulationSnapshotIdSelector = (state: PlanningState): Uuid | null =>
  state.ui.pages.planning.selectedSimulationSnapshotId;
export const simulationExcecutionLogSelector = (state: PlanningState): SimulationExcecutionLogEntry[] =>
  state.ui.pages.planning.simulationExcecutionLog;
export const projectPlanningBarTextDisplaymentSelector = (state: PlanningState): BarTextDisplayment =>
  state.ui.pages.planning.projectPlanningBarTextDisplayment;

export const showGantBarTextSelector = createSelector(
  visibleGanttColumnsSelector,
  (visibleColumns) => visibleColumns.includes('hideAll') || !visibleColumns.includes('name'),
);

const baseSetting: BaselineComparisonNodeSetting = {
  extendedNodes: null,
  selectedBaselines: [],
  visibleColumns: ['name', 'plannedStart', 'plannedEnd', 'menu'],
};

export const getBaselineComparisionSettingForNodeSelector = createSelector(baselineComparisonSelector, (baseline) =>
  memoize((nodeId: MaybeUuid): BaselineComparisonNodeSetting => {
    const settings = baseline.nodeSetting[nodeId!];
    if (settings) {
      return mergeStates(baseSetting, settings);
    }
    return baseSetting;
  }),
);

export const getSelectedBaselineIdsSelector = createSelector(
  getBaselineComparisionSettingForNodeSelector,
  (getSettings) => (nodeId: MaybeUuid) => getSettings(nodeId).selectedBaselines,
);
export const getExtendedNodesForBaselineSelector = createSelector(
  getBaselineComparisionSettingForNodeSelector,
  (getSettings) => (nodeId: MaybeUuid) => getSettings(nodeId).extendedNodes,
);
export const getVisibleColumnsForBaselineSelector = createSelector(
  getBaselineComparisionSettingForNodeSelector,
  (getSettings) => (nodeId: MaybeUuid) => getSettings(nodeId).visibleColumns,
);

const planningAdvancedFilterStatesSelector = createSelector(
  currentOrgUserSettingsSelector,
  (userSettings) => userSettings.planning?.advancedFilterStates,
);

const getPlanningFilterStatesSelector = createSelector(planningAdvancedFilterStatesSelector, (advancedFilterStates) =>
  memoize((page: PlanningPages): Partial<PlanningFilterStates> => {
    return mergeFilterStatesWithDefaults(advancedFilterStates, defaultPlanningFilterStates, page);
  }),
);

export function requireDefaultValue(page: PlanningPages, ident: keyof PlanningFilterStates): unknown {
  const filter = defaultPlanningFilterStates[page][ident];
  if (!filter || !filter.hasOwnProperty('value')) {
    throw new Error(`Missing default planning filter value for page '${page}' and ident '${ident}'`);
  }
  return filter.value;
}

export const planningActiveFilterStatesSelector = createSelector(
  getPlanningFilterStatesSelector,
  (getPlanningFilterStates) =>
    (page: PlanningPages): Partial<PlanningFilterStates> =>
      getPlanningFilterStates(page),
);

export const planningAdvancedFilterIsActiveSelector = createSelector(
  planningActiveFilterStatesSelector,
  (active) =>
    (page: PlanningPages): boolean =>
      doFilterStatesDeviateFromDefaults(active(page), defaultPlanningFilterStates[page]),
);

const planningFilterQuerySelector = createSelector(
  planningActiveFilterStatesSelector,
  projectPlanningSelectedProjectsSelector,
  teamPlanningShowTasksSelector,
  teamPlanningShowTasksWithoutOwnPlanningSelector,
  teamPlanningShowCompletedTasksSelector,
  todayIsoDateSelector,
  (
    getActiveFilter,
    projectIds,
    teamPlanningShowTasks,
    teamPlanningShowTasksWithoutOwnPlanning,
    teamPlanningShowCompletedTasks,
    todayIsoDate,
  ) =>
    memoize((page: PlanningPages): NodeSearchCondition => {
      const {
        customerIds,
        labelIds,
        projectFolderIds,
        projectIds: filterProjectIds,
        responsibleUnits,
        show3MonthPlanning,
        taskIsDone,
        unitRoleIds,
        workPackageIsApprovedForBilling,
        workPackageIsArchived,
        workPackageIsCompleted,
        workPackageIsLocked,
        workPackageIsOffer,
      } = getActiveFilter(page);
      const notArchived: NodeSearchCondition = {not: ['isArchived']};
      const wpConditions: Array<NodeSearchCondition | null> = [
        ['nodeType', EnumFlowNodeType.VALUE_WORK_PACKAGE],
        //dont add `notArchived` here - it is controlled via `workPackageIsArchived` below
      ];
      const subWpConditions: Array<NodeSearchCondition | null> = [
        ['nodeType', EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE],
        notArchived,
      ];
      const tasksConditions: Array<NodeSearchCondition | null> = [
        ['nodeType', EnumFlowNodeType.VALUE_TASK],
        notArchived,
      ];
      const groupsConditions: Array<NodeSearchCondition | null> = [
        ['nodeType', EnumFlowNodeType.VALUE_GROUP],
        notArchived,
      ];

      if (page === 'ganttProjectPlanning') {
        if (projectIds.length) {
          const condition: NodeSearchCondition = {or: projectIds.map((id) => withDescendants(id, true))};
          wpConditions.push(condition);
          subWpConditions.push(condition);
          groupsConditions.push(condition);
          tasksConditions.push(condition);
        } else {
          const condition: NodeSearchCondition = emptyNodeSearchResult; //forces zero results
          wpConditions.push(condition);
          subWpConditions.push(condition);
          groupsConditions.push(condition);
          tasksConditions.push(condition);
        }
      } else if (page === 'teamPlanning') {
        wpConditions.push({not: getEffectiveWpsNotPlanned()});
        subWpConditions.push({not: getEffectiveSwpsNotPlanned()});

        if (!teamPlanningShowCompletedTasks) {
          tasksConditions.push(['taskStatus', EnumFlowTaskStatus.VALUE_OPEN]);
        }

        if (teamPlanningShowTasksWithoutOwnPlanning) {
          tasksConditions.push({not: getEffectiveTasksNotPlanned()});
        } else {
          tasksConditions.push({not: ['nodeNotPlanned']});
        }
      }

      if (projectFolderIds && projectFolderIds.isActive) {
        const condition: NodeSearchCondition = {or: projectFolderIds.value.map((id) => withDescendants(id, true))};
        wpConditions.push(condition);
        subWpConditions.push(condition);
        groupsConditions.push(condition);
        tasksConditions.push(condition);
      }

      if (filterProjectIds && filterProjectIds.isActive) {
        const condition: NodeSearchCondition = {or: filterProjectIds.value.map((id) => withDescendants(id, true))};
        wpConditions.push(condition);
        subWpConditions.push(condition);
        groupsConditions.push(condition);
        tasksConditions.push(condition);
      }

      if (customerIds && customerIds.isActive) {
        const condition: NodeSearchCondition = {or: customerIds.value.map((id) => ['customerId', id])};
        wpConditions.push(condition);
        subWpConditions.push(condition);
        groupsConditions.push(condition);
        tasksConditions.push(withDescendants(condition, true));
      }

      if (labelIds && labelIds.isActive) {
        const condition = createStringsSearchQueryGenerator('labelId')(labelIds.value);
        wpConditions.push(condition);
        subWpConditions.push(condition);
        groupsConditions.push(condition);
        tasksConditions.push(condition);
      }

      if (responsibleUnits && responsibleUnits.isActive && responsibleUnits.value.length) {
        const condition: NodeSearchCondition = {
          or: responsibleUnits.value.map(({unitId}) => ['responsibleUnitId', unitId]),
        };
        wpConditions.push(condition);
        subWpConditions.push(condition);
        groupsConditions.push(condition);
        tasksConditions.push(condition);
      }

      if (unitRoleIds && unitRoleIds.isActive) {
        const condition = userProjectRoleIdsQueryGenerator(unitRoleIds.value);
        wpConditions.push(condition);
        subWpConditions.push(condition);
        groupsConditions.push(condition);
        tasksConditions.push(condition);
      }

      const boolFilters = [
        [workPackageIsApprovedForBilling, 'wpIsApprovedForBilling'],
        [workPackageIsArchived, 'isArchived'],
        [workPackageIsCompleted, 'wpIsCompleted'],
        [workPackageIsLocked, 'wpIsLocked'],
        [workPackageIsOffer, 'wpIsOffer'],
      ] as const satisfies [FilterState<boolean> | undefined, NodeSearchIdentWithoutValue][];
      boolFilters.forEach(([filter, searchIdent]) => {
        if (filter && filter.isActive) {
          wpConditions.push(
            createBooleanNodeTypeSearchQueryGenerator(
              searchIdent,
              EnumFlowNodeType.VALUE_WORK_PACKAGE,
              true,
            )(filter.value),
          );
        }
      });

      if (taskIsDone && taskIsDone.isActive) {
        tasksConditions.push([
          'taskStatus',
          taskIsDone.value ? EnumFlowTaskStatus.VALUE_COMPLETE : EnumFlowTaskStatus.VALUE_OPEN,
        ]);
      }

      if (show3MonthPlanning && show3MonthPlanning.isActive) {
        //false value === all
        if (show3MonthPlanning.value) {
          const startDate = toIsoFormat(fromIsoFormat(todayIsoDate).subtract(90, 'd'));
          wpConditions.push({not: ['nodePlanningEndsBeforeDate', startDate]});
          subWpConditions.push({not: ['nodePlanningEndsBeforeDate', startDate]});
          tasksConditions.push({not: ['nodePlanningEndsBeforeDate', startDate]});
        }
      } else {
        const startDate = toIsoFormat(fromIsoFormat(todayIsoDate).subtract(14, 'd'));
        wpConditions.push({not: ['nodePlanningEndsBeforeDate', startDate]});
        subWpConditions.push({not: ['nodePlanningEndsBeforeDate', startDate]});
        tasksConditions.push({not: ['nodePlanningEndsBeforeDate', startDate]});
      }

      const condition: Array<NodeSearchCondition> = [
        {and: boolFilter(wpConditions)},
        {and: boolFilter(subWpConditions)},
        {and: boolFilter(groupsConditions)},
      ];
      if (page === 'ganttProjectPlanning') {
        condition.push({and: boolFilter(tasksConditions)});
      } else if (page === 'teamPlanning') {
        if (teamPlanningShowTasks) {
          condition.push({and: boolFilter(tasksConditions)});
        }
      }

      return {or: condition};
    }),
);

export const ganttProjectPlanningFilterQueriesSelector = createSelector(
  planningFilterQuerySelector,
  projectPlanningSelectedProjectsSelector,
  (getBbaseQuery, projectIds): NodeSearchCondition => {
    const baseQuery = getBbaseQuery('ganttProjectPlanning');
    return {
      or: [
        {
          and: [withAncestors(baseQuery, true)],
        },
        {
          fixResult: projectIds,
        },
      ],
    };
  },
);

export const materialResourceQuerySelector = createSelector(
  rootFoldersMaterialResourceFolderSelector,
  (rootMaterialResource): NodeSearchCondition => {
    return {
      and: [withDescendants(rootMaterialResource, true), ['mrIsActive']],
    };
  },
);

export const teamPlanningFilterQueriesSelector = createSelector(
  planningFilterQuerySelector,
  rootFoldersProjectFolderSelector,
  (getBbaseQuery, rootProjectFolder) =>
    memoize((userIds: Uuid[]): NodeSearchCondition[] => {
      const baseQuery = getBbaseQuery('teamPlanning');
      const queries = userIds.map<NodeSearchCondition>((userId) => {
        return {
          or: [
            {
              and: [
                withAncestors({and: [baseQuery, getIsResponsible(userId), swpTasks]}, true),
                ['nodeType', EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE],
              ],
            },
            {
              and: [
                withAncestors({and: [baseQuery, getIsResponsible(userId), wpTasks]}, true),
                ['nodeType', EnumFlowNodeType.VALUE_WORK_PACKAGE],
              ],
            },
            {
              and: [
                baseQuery,
                getIsResponsible(userId),
                {
                  or: [
                    ['nodeType', EnumFlowNodeType.VALUE_WORK_PACKAGE],
                    ['nodeType', EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE],
                    ['nodeType', EnumFlowNodeType.VALUE_TASK],
                  ],
                },
              ],
            },
          ],
        };
      });

      const inSubtree = withDescendants(rootProjectFolder, true);
      queries.push({
        or: [
          {
            and: [
              withAncestors({and: [baseQuery, {not: ['responsibleByAny']}, swpTasks]}, true),
              ['nodeType', EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE],
              inSubtree,
            ],
          },
          {
            and: [
              withAncestors({and: [baseQuery, {not: ['responsibleByAny']}, wpTasks]}, true),
              ['nodeType', EnumFlowNodeType.VALUE_WORK_PACKAGE],
              inSubtree,
            ],
          },
          {
            and: [
              baseQuery,
              {not: ['responsibleByAny']},

              {
                or: [
                  ['nodeType', EnumFlowNodeType.VALUE_WORK_PACKAGE],
                  ['nodeType', EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE],
                  ['nodeType', EnumFlowNodeType.VALUE_TASK],
                ],
              },
              inSubtree,
            ],
          },
        ],
      });
      return queries;
    }),
);
