import {Uuid} from '@octaved/typescript/src/lib';
import {Pipette, Trash} from 'lucide-react';
import {ReactElement, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {TaskSection} from '../../../EntityInterfaces/TaskSection';
import {nodeTreeInvertedSelector, nodeTreeSelector} from '../../../Modules/Selectors/NodeTreeSelectors';
import {deleteTaskSection, patchTaskSection} from '../../../Modules/TaskSections';
import ConfirmDialog from '../../Dialog/ConfirmDialog';
import {DropdownPopupItemOptions, DropdownPopupItemType} from '../../Form/Dropdown/DropdownPopup/Types';
import {useTaskListContext} from '../TaskListContext';
import {useDragTaskSection} from '../TaskListForNode/DragAndDrop/DragItems';
import {OnEnterKeyDown} from '../TaskListForNode/EditName';
import {useNameSnapshot} from '../TaskListForNode/NameSnapshot';
import TaskList from '../TaskListForNode/Task/TaskList';
import TaskSectionName from '../TaskListForNode/TaskSection/TaskSectionName';
import {useTaskListReadonlyContext} from '../TaskListReadonlyContext';
import {useNewTaskOption, useNewTaskSectionOption} from './Options';
import BaseTaskGroup from './TaskGroup';
import {useTaskGroupContext} from './TaskGroupContext';
import TaskSectionColorPicker from './TaskSectionColorPicker';

const onEnterKeyDown: OnEnterKeyDown = ({blur}) => {
  blur();
};

interface Props {
  index: number;
  parentNodeId: Uuid;
  taskSection: TaskSection;
}

function createUseMenuOptions(
  openConfirmDelete: () => void,
  canChangeColor: boolean,
  openColorPicker: () => void,
): () => DropdownPopupItemOptions[] {
  return () => {
    const {id} = useTaskGroupContext();
    const nodeTree = useSelector(nodeTreeSelector);
    const parentNodeId = nodeTree[id];
    const {readonlyOrNotManageable} = useTaskListReadonlyContext();
    const newTaskOption = useNewTaskOption(id);
    const newTaskSectionOption = useNewTaskSectionOption(parentNodeId || '');
    const {allowCreationOfTasks, allowCreationOfTaskSections} = useTaskListContext();
    const options: DropdownPopupItemOptions[] = [];

    if (!readonlyOrNotManageable) {
      if (allowCreationOfTasks) {
        options.push(newTaskOption);
      }
      if (allowCreationOfTaskSections) {
        options.push(newTaskSectionOption);
      }
    }

    if (!readonlyOrNotManageable) {
      if (canChangeColor) {
        options.push(
          {type: DropdownPopupItemType.divider},
          {
            icon: <Pipette />,
            iconColor: 'darkGrey',
            onMouseDown: openColorPicker,
            token: 'taskList:customizeColor',
            type: DropdownPopupItemType.item,
          },
        );
      }

      options.push({
        icon: <Trash />,
        iconColor: 'red',
        onMouseDown: () => (dispatch, getState) => {
          const childIds = nodeTreeInvertedSelector(getState()).get(id) || [];
          if (childIds.length) {
            openConfirmDelete();
          } else {
            dispatch(deleteTaskSection(id));
          }
        },
        textColor: 'red',
        token: 'taskList:groupMenu.deleteTaskSectionLabel',
        type: DropdownPopupItemType.item,
      });
    }

    return options;
  };
}

export default function TaskSectionGroup({index, parentNodeId, taskSection}: Props): ReactElement {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const useMenuOptions = createUseMenuOptions(
    () => setShowConfirmDelete(true),
    true,
    () => setShowColorPicker(true),
  );

  const {dragPreviewRef, dragRef, dropRef, isDragging} = useDragTaskSection(taskSection, index, parentNodeId);

  const name = useNameSnapshot(taskSection, (partial) => dispatch(patchTaskSection(taskSection.id, partial)));
  const {TaskGroup = BaseTaskGroup} = useTaskListContext();

  return (
    <>
      <TaskGroup
        id={taskSection.id}
        isDragging={isDragging}
        dragRef={dragRef}
        dropRef={dropRef}
        ref={dragPreviewRef}
        name={
          <TaskSectionName onBlur={name.commit} name={name.value} patch={name.patch} onEnterKeyDown={onEnterKeyDown} />
        }
        useMenuOptions={useMenuOptions}
      >
        <TaskList parentId={taskSection.id} />
      </TaskGroup>

      {showConfirmDelete && (
        <ConfirmDialog
          title={'taskList:confirmDeleteSection.title'}
          buttonLabel={'taskList:confirmDeleteSection.buttonLabel'}
          buttonIcon={<Trash />}
          buttonColorScheme={'delete'}
          cancelCallback={() => setShowConfirmDelete(false)}
          submitCallback={() => {
            dispatch(deleteTaskSection(taskSection.id));
            setShowConfirmDelete(false);
          }}
        >
          <span>{t('taskList:confirmDeleteSection.text')}</span>
        </ConfirmDialog>
      )}

      {showColorPicker && (
        <TaskSectionColorPicker
          onClose={() => setShowColorPicker(false)}
          sectionId={taskSection.id}
          triggerRef={dragRef}
        />
      )}
    </>
  );
}
