import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {useInspectorId} from '@octaved/hooks';
import {useNodeTableContextOptional} from '@octaved/node-table/src/NodeTableContext';
import {Uuid} from '@octaved/typescript/src/lib';
import {useCallback} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {closeDialogRoute} from '../Routing/Routes/DialogRoutes';
import {useModalContext} from '../Dialogs/DialogFrame';
import {awaitSelector} from '@octaved/store/src/AwaitSelector';
import {nodeEntitySelector} from '../Modules/Selectors/NodeSelectors';

export function useCloseAndSelectNewNode(): (nodeId: Uuid, nodeType: EnumFlowNodeType) => void {
  const selectedInspectorId = useInspectorId();
  const location = useLocation();
  const navigate = useNavigate();
  const close = useModalContext()?.close;
  const setNodeTableRowId = useNodeTableContextOptional()?.setSelectedRowId;
  return useCallback(
    async (nodeId, nodeType) => {
      let target = `../../${nodeId}`;
      if (location.pathname.includes('/node/')) {
        target = `/node/${nodeType}/${nodeId}/overview`;
        navigate(target + window.location.search);
      } else if (selectedInspectorId) {
        target = location.pathname.substr(0, location.pathname.indexOf(selectedInspectorId)) + nodeId;
        navigate(target + window.location.search);
      } else if (location.pathname.includes('dialog')) {
        navigate(closeDialogRoute);
      } else if (close) {
        close();
      }
      await awaitSelector((state) => nodeEntitySelector(state)[nodeId]);
      setNodeTableRowId?.(nodeId);
    },
    [location.pathname, navigate, close, selectedInspectorId, setNodeTableRowId],
  );
}
