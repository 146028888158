import DialogAndDrawerHeader from '@octaved/flow/src/Components/Layout/DialogAndDrawerHeader';
import {DialogContent, DialogFrame, DialogTitle} from '@octaved/flow/src/Dialogs/DialogFrame';
import TextInput from '@octaved/ui-components/src/React/Formik/TextInput';
import VerticalForm from '@octaved/ui-components/src/React/Formik/VerticalForm';
import {useFormik} from 'formik';
import {PlusCircle} from 'lucide-react';
import {Dispatch, ReactElement, SetStateAction, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';

interface DurationSelectionModalProps {
  setOpen: Dispatch<SetStateAction<boolean>>;
  onSave: (duration: number) => void;
  duration: number;
  maxDuration?: number;
}

export default function DurationSelectionModal({
  setOpen,
  onSave,
  duration,
  maxDuration,
}: DurationSelectionModalProps): ReactElement {
  const {t} = useTranslation();

  const durationSchema = useMemo(() => {
    let durationNumberSchema = Yup.number()
      .typeError({
        i18nKey: 'general:validationError.integer',
        values: {field: t('pages:projects.inspector.manage.planning.duration')},
      })
      .min(0, {
        i18nKey: 'general:validationError.min',
        values: {field: t('pages:projects.inspector.manage.planning.duration'), min: 0},
      })
      .integer({
        i18nKey: 'general:validationError.integer',
        values: {field: t('pages:projects.inspector.manage.planning.duration')},
      })
      .required({
        i18nKey: 'general:validationError.required',
        values: {field: t('pages:projects.inspector.manage.planning.duration')},
      });
    if (maxDuration !== undefined) {
      durationNumberSchema = durationNumberSchema.max(maxDuration, {
        i18nKey: 'general:validationError.max',
        values: {field: t('pages:projects.inspector.manage.planning.duration'), max: maxDuration},
      });
    }
    return Yup.object().shape({
      duration: durationNumberSchema,
    });
  }, [t, maxDuration]);

  const formik = useFormik({
    initialValues: {duration},
    onSubmit: async (values) => {
      onSave(+values.duration);
      setOpen(false);
    },
    validationSchema: durationSchema,
  });
  return (
    <DialogFrame noScroll size={'mini'} noOwnForm>
      <DialogTitle onClose={() => setOpen(false)} growTitle>
        <DialogAndDrawerHeader header={'pages:projects.inspector.manage.planning.duration'} noPadding />
      </DialogTitle>
      <DialogContent>
        <VerticalForm submitLabel={'general:save'} submitIcon={<PlusCircle />} formik={formik}>
          <TextInput label={'pages:projects.inspector.manage.planning.daysLabel'} formik={formik} id={'duration'} />
        </VerticalForm>
      </DialogContent>
    </DialogFrame>
  );
}
