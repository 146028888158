import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {error} from '@octaved/env/src/Logger';
import {copyPid} from '@octaved/flow-api';
import {CALL_API} from '@octaved/network/src/NetworkMiddlewareTypes';
import {ActionDispatcher} from '@octaved/store/src/Store';
import {createTextInputVariableRules, notNullOrUndefined, RulesList} from '@octaved/store/src/Validation';
import {DateStr} from '@octaved/typescript';
import {Uuid} from '@octaved/typescript/src/lib';
import {generateUuid} from '@octaved/utilities';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import {NodeEntity} from '../EntityInterfaces/NodeEntity';
import {NodeType} from '../EntityInterfaces/Nodes';
import {Pid} from '../EntityInterfaces/Pid';
import {isGroup, isPid, isProject, isProjectFolder, isTimeControlledNode, isWorkPackage} from '../Node/NodeIdentifiers';
import {FLOW_COPY_PID_FAILURE, FLOW_COPY_PID_REQUEST, FLOW_COPY_PID_SUCCESS} from './ActionTypes';
import {CopyPidRequestEvent, EventData} from './Events';
import {copyGroupEntity} from './Groups';
import {copyProjectEntity} from './Projects';
import {nodeEntitySelector} from './Selectors/NodeSelectors';
import {getChildNodesSelector} from './Selectors/NodeTreeSelectors';
import {FlowState} from './State';
import {copyTaskEntity} from './Tasks';
import {copyTaskSectionEntity} from './TaskSections';
import {removeErrorForField, validateErrorRules} from './Ui';
import {copyWorkPackageEntity} from './WorkPackages';

const copyMethods: Partial<{
  [A in EnumFlowNodeType]: (source: NodeType & {nodeType: A}, targetNode: NodeType) => NodeType & {nodeType: A};
}> = {
  [EnumFlowNodeType.VALUE_GROUP]: copyGroupEntity,
  [EnumFlowNodeType.VALUE_PROJECT]: copyProjectEntity,
  [EnumFlowNodeType.VALUE_TASK]: copyTaskEntity,
  [EnumFlowNodeType.VALUE_TASK_SECTION]: copyTaskSectionEntity,
  [EnumFlowNodeType.VALUE_WORK_PACKAGE]: copyWorkPackageEntity,
};

export function copyNode<N extends NodeEntity>(sourceNode: N, targetNode: NodeType): N {
  const copyMethod = copyMethods[sourceNode.nodeType];
  if (!copyMethod) {
    throw new Error(`Missing copy method for node type ${sourceNode.nodeType}`);
  }
  return (copyMethod as unknown as (source: N, targetNode: NodeType) => N)(sourceNode, targetNode);
}

export interface CopyPidOptions {
  copyAssignments?: boolean;
  copyPlanning?: {
    alignment: 'start' | 'end';
    targetDate: DateStr | null;
  };
  copyTasks?: boolean;
  timeControlFromOverrides?: Record<Uuid, DateStr>;
}

function copyPidAction(
  sourceNodeId: Uuid,
  targetParentNodeId: Uuid,
  copyNodeId: Uuid,
  newName: string,
  {copyAssignments, copyPlanning, copyTasks, timeControlFromOverrides = {}}: CopyPidOptions = {},
): ActionDispatcher<Promise<boolean>, FlowState> {
  return async (dispatch, getState) => {
    const state = getState();
    const nodes = nodeEntitySelector(state);
    const sourceNode = nodes[sourceNodeId];
    const targetNode = nodes[targetParentNodeId];
    if (!sourceNode) {
      throw new Error(`Missing source node '${sourceNodeId}'`);
    }
    if (!targetNode) {
      throw new Error(`Missing target node '${targetParentNodeId}'`);
    }
    if (isProject(sourceNode) && !isProjectFolder(targetNode)) {
      throw new Error('Projects must have project folders as duplicationg target');
    } else if ((isGroup(sourceNode) || isWorkPackage(sourceNode)) && !isProject(targetNode) && !isGroup(targetNode)) {
      throw new Error('Groups/work packages must have projects or groups as duplicationg target');
    }

    const rules: RulesList = [
      ...createTextInputVariableRules(
        newName,
        'general:node.nameEmptyError',
        'general:node.nameTooLongError',
        `copy_pid_${sourceNodeId}_name`,
      ),
      typeof copyPlanning !== 'undefined' && [
        notNullOrUndefined,
        copyPlanning.targetDate,
        'dialogs:duplicatePid.copyPlanning.error.noDate',
        `copy_pid_${sourceNodeId}_copyPlanning_date`,
      ],
    ];
    if (!validateErrorRules(rules, dispatch)) {
      return false;
    }

    const copiedNode = {
      ...copyNode(sourceNode, targetNode),
      id: copyNodeId,
      name: newName,
    };
    if (isTimeControlledNode(copiedNode) && copiedNode.timeControl && timeControlFromOverrides[sourceNodeId]) {
      copiedNode.timeControl = {...copiedNode.timeControl, from: timeControlFromOverrides[sourceNodeId]};
    }

    let incrementSortOrderPidIds: Uuid[] = [];
    if ((isGroup(sourceNode) || isWorkPackage(sourceNode)) && (isGroup(copiedNode) || isWorkPackage(copiedNode))) {
      const siblings = getChildNodesSelector(state)(targetParentNodeId).filter((node) => isPid(node)) as Pid[];
      const sourceIsSibling = !!siblings.find(({id}) => id === sourceNodeId);
      if (sourceIsSibling) {
        copiedNode.sortOrder = sourceNode.sortOrder + 1;
        incrementSortOrderPidIds = siblings
          .filter(({sortOrder}) => sortOrder >= copiedNode.sortOrder)
          .map(({id}) => id);
      }
    }

    const event: EventData<CopyPidRequestEvent> = {
      incrementSortOrderPidIds,
      targetParentNodeId,
      copyNode: copiedNode,
    };

    try {
      await dispatch({
        ...event,
        [CALL_API]: {
          endpoint: copyPid,
          method: 'post',
          options: {
            data: {
              copyAssignments,
              copyPlanning,
              copyTasks,
              timeControlFromOverrides,
              nameOverrides: {[sourceNodeId]: newName},
            },
            urlParams: {
              copyNodeId,
              sourceNodeId,
              targetParentNodeId,
            },
          },
          throwNetworkError: true,
          types: {
            failureType: FLOW_COPY_PID_FAILURE,
            requestType: FLOW_COPY_PID_REQUEST,
            successType: FLOW_COPY_PID_SUCCESS,
          },
        },
      });
      return true;
    } catch (e) {
      error(e);
      return false;
    }
  };
}

export function useCopyPid(sourceNodeId: Uuid): {
  copy: (targetParentNodeId: Uuid, newName: string, options?: CopyPidOptions) => Promise<false | Uuid>;
  errorFields: string[];
  isCopying: boolean;
} {
  const dispatch = useDispatch();
  const [isCopying, setCopying] = useState(false);
  const isCopyingRef = useRef(isCopying);

  const errorFields = useMemo(
    () => [`copy_pid_${sourceNodeId}_name`, `copy_pid_${sourceNodeId}_copyPlanning_date`],
    [sourceNodeId],
  );

  useEffect(() => {
    return () => {
      dispatch(removeErrorForField(errorFields));
    };
  }, [dispatch, errorFields]);

  const copy = useCallback(
    async (targetParentNodeId, newName, options) => {
      if (isCopyingRef.current) {
        return false;
      }
      isCopyingRef.current = true;
      setCopying(isCopyingRef.current);

      const copyNodeId = generateUuid();
      const success = await dispatch(copyPidAction(sourceNodeId, targetParentNodeId, copyNodeId, newName, options));

      isCopyingRef.current = false;
      setCopying(isCopyingRef.current);

      return success ? copyNodeId : false;
    },
    [dispatch, sourceNodeId],
  );

  return {isCopying, copy, errorFields};
}
