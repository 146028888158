import {IconButton} from '@octaved/ui';
import {MoreHorizontal} from 'lucide-react';
import {ReactElement, useContext} from 'react';
import DropdownPopup from '../../Components/Form/Dropdown/DropdownPopup';
import {inspectorContext} from '../InspectorContext/InspectorContext';
import {useInspectorSubMenu} from './InspectorSubMenu';

export default function StandardInspectorSubMenu(): ReactElement {
  const context = useContext(inspectorContext);
  const node = context?.selectedEntity;
  const menuOptions = useInspectorSubMenu(node);

  return (
    <DropdownPopup items={menuOptions} closeOnSelect position={'bottom right'}>
      <IconButton variant={'ghost'} icon={<MoreHorizontal />} size={'sm'} />
    </DropdownPopup>
  );
}
