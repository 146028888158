import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import NodeName from '@octaved/flow/src/Components/Node/NodeName';
import StringPath from '@octaved/flow/src/Components/Node/StringPath';
import {DEFAULT_INSPECTOR_WIDTH} from '@octaved/flow/src/Drawer/StandardInspector/StandardInspectorPage';
import {isPid, isSubWorkPackage, isTask} from '@octaved/flow/src/Node/NodeIdentifiers';
import {cn, tableGridCellVariant} from '@octaved/ui';
import {ReactElement} from 'react';
import {PatchPlanningDependency} from '../../../../Modules/Planning';
import PredecessorStatus from '../PredecessorStatus';
import {DependencyListRowProps} from './DependencyList';
import DependencyTypeSeletion from './DependencyTypeSelection';
import Menu from './Menu';
import Offset from './Offset';

export default function PredecessorDependencyListRow({
  node,
  dependency,
  readonly,
}: DependencyListRowProps<PatchPlanningDependency>): ReactElement | null {
  if (!isPid(node) && !isTask(node) && !isSubWorkPackage(node)) {
    return null;
  }

  return (
    <div className={'contents'}>
      <div
        className={cn(tableGridCellVariant({position: 'left', className: 'flex flex-col justify-between'}))}
        style={{maxWidth: `calc(${DEFAULT_INSPECTOR_WIDTH} - 200px)`}}
      >
        <div className={'truncate'}>
          <NodeName nodeId={dependency.predecessor} />
        </div>
        {node.nodeType !== EnumFlowNodeType.VALUE_TASK && (
          <div className={'text-sm text-slate-600'}>
            <StringPath nodeId={dependency.predecessor} excludeSelf />
          </div>
        )}
      </div>
      <div className={cn(tableGridCellVariant())}>
        <DependencyTypeSeletion dependency={dependency} node={node} readonly={readonly} />
      </div>
      <div className={cn(tableGridCellVariant())}>
        <Offset dependency={dependency} node={node} readonly={readonly} />
      </div>
      <div className={cn(tableGridCellVariant({position: 'right'}))}>
        {!readonly && <Menu dependency={dependency} node={node} />}
      </div>

      <PredecessorStatus nodeId={dependency.predecessor} />

      {/*#region styles*/}
      {/*language=scss*/}
      <style jsx>{`
        .cell :global(.ui.selection.dropdown),
        .cell :global(.ui.selection.dropdown > .dropdown.icon) {
          min-height: 1.5em;
          padding-top: 5px;
          padding-bottom: 5px;
          padding-right: 25px;
        }

        .cell :global(.ui.selection.dropdown > .dropdown.icon) {
          padding-right: 5px;
        }
      `}</style>
      {/*#endregion*/}
    </div>
  );
}
