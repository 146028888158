import {useDisposedSafeCallback} from '@octaved/hooks';
import {StopClickPropagation} from '@octaved/ui';
import classNames from 'classnames';
import {AnimatePresence, AnimationProps, motion, Variants} from 'framer-motion';
import {ReactElement, ReactNode, RefObject, useEffect, useRef, useState} from 'react';
import {Popup, StrictPopupProps} from 'semantic-ui-react';
import {useFlowStyle} from '../../../Styles/StyleContext';
import {useCloseContextMenu} from './ContextMenuRoot';
import Item from './DropdownPopup/Item';
import {style} from './DropdownPopup/Style';
import {DropdownPopupItemOptions} from './DropdownPopup/Types';

export interface DropdownPopupProps extends StrictPopupProps, AnimationProps {
  children?: ReactNode;
  closeOnSelect?: boolean;
  hovering?: string;
  inDrawer?: boolean;
  items: ReadonlyArray<DropdownPopupItemOptions>;
  noIcon?: boolean;
  scrollOverflow?: boolean;
  maxHeight?: string;

  onClose?(): void;
}

const variants: Variants = {
  hidden: {marginTop: 10, opacity: 0, transition: {duration: 0.12}},
  visible: {marginTop: 0, opacity: 1, transition: {duration: 0.12}},
};

function useScrollView(hoverIndex?: string): {scrollContainerRef: RefObject<HTMLDivElement>} {
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (hoverIndex && scrollContainerRef.current) {
      const scrollContainer = scrollContainerRef.current.parentElement as HTMLDivElement;
      const hoveringItem = scrollContainerRef.current.querySelector('.hovering') as HTMLDivElement | undefined;
      if (hoveringItem) {
        const topBoundary = hoveringItem.offsetTop;
        const bottomBoundary = topBoundary + hoveringItem.clientHeight;
        if (scrollContainer.scrollTop > topBoundary) {
          scrollContainer.scrollTop = topBoundary;
        } else if (scrollContainer.scrollTop + scrollContainer.clientHeight < bottomBoundary) {
          scrollContainer.scrollTop = bottomBoundary - scrollContainer.clientHeight;
        }
      }
    }
  }, [hoverIndex, scrollContainerRef]);

  return {
    scrollContainerRef,
  };
}

export default function DropdownPopup({
  children,
  noIcon = false,
  inDrawer,
  items,
  hovering,
  closeOnSelect = false,
  scrollOverflow,
  maxHeight,
  onClose,
  ...props
}: DropdownPopupProps): ReactElement | null {
  const {styles, className} = useFlowStyle(style(maxHeight));
  const [open, _setOpen] = useState(false);
  //Async callbacks may lead to this being called when already disposed
  const setOpen = useDisposedSafeCallback(_setOpen);
  const {scrollContainerRef} = useScrollView(hovering);
  const closeContextMenu = useCloseContextMenu();

  useEffect(() => {
    if (props.hasOwnProperty('open')) {
      setOpen(props.open!);
    }
  }, [props, setOpen]);

  if (items.length === 0 || items.every((i) => i.hidden)) {
    return null;
  }

  return (
    <>
      <AnimatePresence>
        <Popup
          className={classNames(className, 'popup', {inDrawer, scrollOverflow})}
          basic
          position={'bottom right'}
          on={'click'}
          as={motion.div}
          trigger={<StopClickPropagation>{children}</StopClickPropagation>}
          initial={'hidden'}
          animate={open ? 'visible' : 'hidden'}
          variants={variants}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => {
            setOpen(false);
            closeContextMenu();
            onClose?.();
          }}
          flowing
          style={inDrawer ? {zIndex: 2051} : undefined}
          {...props}
        >
          <div className={'scrollContainer'} ref={scrollContainerRef}>
            {open &&
              items.map((item, idx) => (
                <Item
                  key={idx}
                  className={className}
                  closeOnSelect={closeOnSelect}
                  hovering={hovering}
                  item={item}
                  noIcon={noIcon}
                  onClose={() => {
                    closeContextMenu();
                    setOpen(false);
                  }}
                />
              ))}
          </div>
        </Popup>
      </AnimatePresence>
      {styles}
    </>
  );
}
