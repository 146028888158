import CannotViewDetailsPlaceholder from '@octaved/node-details/src/Components/CannotViewDetailsPlaceholder';
import {isPlanningDateInspectorContext} from '@octaved/planning/src/Inspector/PlanningDateInspectorContext';
import PlanningDateFooter from '@octaved/planning/src/Pages/Gantt/Components/Inspector/PlanningDateFooter';
import {isGrantedSelector} from '@octaved/security/src/Authorization/Authorization';
import {ActionDispatcher} from '@octaved/store/src/Store';
import {ComponentType, ReactElement, ReactNode} from 'react';
import {useSelector} from 'react-redux';
import {Selector} from 'reselect';
import Drawer from '../../Components/Feedback/Drawer/Drawer';
import FlowOutlet from '../../Components/FlowOutlet';
import InspectorFrame from '../../Components/Layout/Inspector/InspectorFrame';
import {drawerPinnedSelector} from '../../Modules/Selectors/UiSelectors';
import {FlowState} from '../../Modules/State';
import {isNode, isTask} from '../../Node/NodeIdentifiers';
import ProjectsInspectorContext from '../../Pages/Projects/Inspector/ProjectsInspectorContext';
import {useInspectorContext} from '../InspectorContext/InspectorContext';
import TaskFooter from '../Task/TaskFooter';
import DemoMode from './DemoMode';
import StandardInspectorHeader from './StandardInspectorHeader';
import {DEFAULT_INSPECTOR_WIDTH} from './StandardInspectorPage';
import StandardInspectorTabs, {TabRecords} from './StandardInspectorTabs';

export interface StandardInspectorProps {
  actionMenu?: ComponentType;
  footerElement?: ReactElement;
  headerTitle?: ReactNode;
  canMarkAsDone?: boolean;
  tabConfig?: TabRecords;
  demoModeSelector?: Selector<FlowState, boolean>;
  demoModeToggle?: (demoMode: boolean) => ActionDispatcher<void, FlowState>;
}

export default function StandardInspector({
  actionMenu,
  footerElement,
  headerTitle,
  tabConfig,
  demoModeSelector,
  demoModeToggle,
  canMarkAsDone = true,
}: StandardInspectorProps): ReactElement {
  const drawerPinned = useSelector(drawerPinnedSelector);
  const context = useInspectorContext();
  const isGranted = useSelector(isGrantedSelector);

  let canViewDetails = true;
  if (isNode(context.selectedEntity) && !isGranted('FLOW_NODE_VIEW_DETAILS', context.selectedId)) {
    canViewDetails = false;
  }

  let footer = footerElement;
  if (!footerElement && isTask(context.selectedEntity)) {
    footer = <TaskFooter task={context.selectedId} />;
  }
  if (isPlanningDateInspectorContext(context)) {
    footer = <PlanningDateFooter />;
  }
  if (
    footerElement &&
    (location.pathname.startsWith('/createOffer') ||
      location.pathname.startsWith('/projectPlanning') ||
      location.pathname.startsWith('/teamPlanning'))
  ) {
    footer = undefined;
  }

  return (
    <Drawer visible animationKey={'pidInspector'} emptyInspector pinned={drawerPinned && !context.isSubInspector}>
      <InspectorFrame
        header={
          <>
            {demoModeSelector && demoModeToggle && (
              <DemoMode demoModeSelector={demoModeSelector} demoModeToggle={demoModeToggle} />
            )}
            <StandardInspectorHeader actionMenu={actionMenu} headerTitle={headerTitle} canMarkAsDone={canMarkAsDone} />
          </>
        }
        footer={footer}
      >
        <div className={'container'}>
          {canViewDetails ? (
            <>
              <StandardInspectorTabs tabConfig={tabConfig} />
              <ProjectsInspectorContext.Provider value={{id: context.selectedId}}>
                <FlowOutlet />
              </ProjectsInspectorContext.Provider>
            </>
          ) : (
            <CannotViewDetailsPlaceholder />
          )}
        </div>
        {/*#region styles*/}
        {/*language=scss*/}
        <style jsx>{`
          .container {
            width: auto;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            overflow: hidden;
            flex-grow: 1;
            min-width: ${DEFAULT_INSPECTOR_WIDTH};
            width: fit-content;
          }
        `}</style>
        {/*#endregion*/}
      </InspectorFrame>
    </Drawer>
  );
}

StandardInspector.displayName = 'StandardInspector';
