import {ReactElement, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Dropdown, DropdownItemProps} from 'semantic-ui-react';
import DurationSelectionModal from './DurationSelectionModal';

interface DurationSelectionProps {
  disabled?: boolean;
  duration: number;
  onChange: (duration: number) => void;
  maxDuration?: number;
}

const MAX_DROPDOWN_DURATION = 30;

export default function DurationSelection({
  disabled,
  duration,
  onChange,
  maxDuration,
}: DurationSelectionProps): ReactElement {
  const {t} = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);

  const options = useMemo<DropdownItemProps[]>(() => {
    const itemCount = Math.min(MAX_DROPDOWN_DURATION, maxDuration || MAX_DROPDOWN_DURATION);
    const values = Array.from<Array<unknown>, DropdownItemProps>({length: itemCount}, (_, i) => ({
      text: i + 1,
      value: i + 1,
    }));

    if (duration > itemCount) {
      values.push({
        text: duration,
        value: duration,
      });
    }
    if (!maxDuration || MAX_DROPDOWN_DURATION < maxDuration) {
      values.push({
        text: t('pages:projects.inspector.manage.planning.otherValue'),
        value: false,
      });
    }
    return values;
  }, [duration, maxDuration, t]);

  return (
    <div className={'duration flex items-center gap-x-2 py-px'}>
      <div>
        <Dropdown
          direction={'left'}
          fluid
          selection
          options={options}
          value={duration}
          placeholder={'0'}
          onChange={(_, {value}) => {
            if (typeof value === 'number') {
              onChange(value);
            } else if (value === false) {
              setModalOpen(true);
            }
          }}
          disabled={disabled}
        />
        {modalOpen && (
          <DurationSelectionModal
            setOpen={setModalOpen}
            onSave={onChange}
            duration={duration}
            maxDuration={maxDuration}
          />
        )}
      </div>
      <span>
        {t('general:days', {
          count: +duration || 0,
        })}
      </span>

      {/*#region styles*/}
      {/*language=scss*/}
      <style jsx>{`
        .duration :global(.ui.selection.dropdown),
        .duration :global(.ui.selection.dropdown > .dropdown.icon) {
          min-height: 1.5em;
          padding-top: 5px;
          padding-bottom: 5px;
          padding-right: 25px;
        }

        .duration :global(.ui.selection.dropdown > .dropdown.icon) {
          padding-right: 5px;
        }

        .duration :global(.left.visible.menu) {
          width: 130px;
          border-top-width: 1px !important;
        }

        .duration :global(.upward .left.visible.menu) {
          border-bottom-width: 1px !important;
          max-height: 130px;
        }
      `}</style>
      {/*#endregion*/}
    </div>
  );
}
