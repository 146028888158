import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {settingsRoute, specialPlanningRoute} from '@octaved/node-details/src/LargeView/RoutePaths';
import {anyNode} from '@octaved/security/src/Authorization/Authorization';
import {Uuid} from '@octaved/typescript/src/lib';
import {ComponentType} from 'react';
import {RouteObject} from 'react-router';
import {Navigate, useParams} from 'react-router-dom';
import {
  InspectorContextSelectedTypes,
  InspectorContextWrapper,
  InspectorParameterNames,
} from '../../../Drawer/InspectorContext/InspectorContext';
import StandardInspectorContext from '../../../Drawer/InspectorContext/StandardInspectorContext';
import StandardInspector, {StandardInspectorProps} from '../../../Drawer/StandardInspector/StandardInspector';
import {InspectorTabItem, TabRecords} from '../../../Drawer/StandardInspector/StandardInspectorTabs';
import {dialogRouteObjects} from '../Dialogs';
import {
  advancedRoute,
  billingsRoute,
  boardRoute,
  customerRoute,
  editTaskRoute,
  generalRoute,
  guestUserRoute,
  historyRoute,
  planningRoute,
  responsibilityRoute,
  statusRoute,
  tasksRoute,
  timeAndMoneyRoute,
} from './InspectorRoutes';
import {
  advancedRouteObject,
  billingsRouteObject,
  boardRouteObject,
  customerRouteObject,
  editTaskRouteObject,
  generalRouteObject,
  guestUserRouteObject,
  historyRouteObject,
  milestonesRouteObject,
  moreRouteObject,
  planningRouteObject,
  responsibilityRouteObject,
  settingsRouteObject,
  statusRouteObject,
  tasksRouteObject,
  timeAndMoneyRouteObject,
  timeTrackingRouteObject,
} from './Inspectors';

export type InspectorRoutes =
  | typeof advancedRoute
  | typeof billingsRoute
  | typeof boardRoute
  | typeof editTaskRoute
  | typeof generalRoute
  | typeof guestUserRoute
  | typeof planningRoute
  | typeof responsibilityRoute
  | typeof tasksRoute
  | typeof timeAndMoneyRoute
  | typeof specialPlanningRoute;

//#region <inspector tabs>
const workPackageTabs: InspectorTabItem[] = [
  {
    path: generalRoute,
    token: 'pages:projects.inspector.menu.general',
  },
  {
    access: ({isGranted}) => isGranted('FLOW_NODE_READ_PROJECT_ROLE_ASSIGNMENTS', anyNode),
    path: responsibilityRoute,
    token: 'pages:projects.inspector.menu.responsibility',
  },
  {
    access: ({isGranted}) => isGranted('FLOW_NODE_READ_PLANNING', anyNode),
    path: planningRoute,
    token: 'pages:projects.inspector.menu.planning',
  },
  {
    access: ({isGranted}) =>
      isGranted('FLOW_NODE_WORK_PACKAGE_READ_BILLING', anyNode) &&
      isGranted('FLOW_NODE_WORK_PACKAGE_READ_EFFORT', anyNode),
    path: timeAndMoneyRoute,
    token: 'pages:projects.inspector.menu.timeAndFinances',
  },
  {
    path: settingsRoute,
    token: 'nodeDetails:tab.settings',
  },
  {
    path: advancedRoute,
    token: 'pages:projects.inspector.menu.advanced',
  },
];

const subWorkPackageTabs: InspectorTabItem[] = [
  {
    path: generalRoute,
    token: 'pages:projects.inspector.menu.general',
  },
  {
    access: ({isGranted}) => isGranted('FLOW_NODE_READ_PROJECT_ROLE_ASSIGNMENTS', anyNode),
    path: responsibilityRoute,
    token: 'pages:projects.inspector.menu.responsibility',
  },
  {
    access: ({isGranted}) => isGranted('FLOW_NODE_READ_PLANNING', anyNode),
    path: planningRoute,
    token: 'pages:projects.inspector.menu.planning',
  },
  {
    access: ({isGranted}) => isGranted('FLOW_NODE_SUB_WORK_PACKAGE_READ_EFFORT', anyNode),
    path: timeAndMoneyRoute,
    token: 'pages:projects.inspector.menu.timeAndFinances',
  },
  {
    path: settingsRoute,
    token: 'nodeDetails:tab.settings',
  },
];

const groupTabs: InspectorTabItem[] = [
  {
    path: generalRoute,
    token: 'pages:projects.inspector.menu.general',
  },
  {
    access: ({isGranted}) => isGranted('FLOW_NODE_READ_PROJECT_ROLE_ASSIGNMENTS', anyNode),
    path: responsibilityRoute,
    token: 'pages:projects.inspector.menu.responsibility',
  },
  {
    access: ({isGranted}) => isGranted('FLOW_NODE_READ_PLANNING', anyNode),
    path: planningRoute,
    token: 'pages:projects.inspector.menu.planning',
  },
  {
    path: statusRoute,
    token: 'pages:projects.inspector.menu.status',
    tooltipTranslation: '',
  },
  {
    access: ({isGranted}) => isGranted('FLOW_NODE_READ_GUEST_PERMISSION_ROLE_ASSIGNMENTS', anyNode),
    path: guestUserRoute,
    token: 'guestUser:inspector.tab',
  },
  {
    path: settingsRoute,
    token: 'pages:projects.inspector.menu.settings',
  },
  {
    path: advancedRoute,
    token: 'pages:projects.inspector.menu.advanced',
  },
];

const projectTabs: InspectorTabItem[] = [
  {
    path: generalRoute,
    token: 'pages:projects.inspector.menu.general',
  },
  {
    access: ({isGranted}) => isGranted('FLOW_NODE_READ_PROJECT_ROLE_ASSIGNMENTS', anyNode),
    path: responsibilityRoute,
    token: 'pages:projects.inspector.menu.responsibility',
  },
  {
    access: ({isGranted}) => isGranted('FLOW_NODE_READ_PLANNING', anyNode),
    path: planningRoute,
    token: 'pages:projects.inspector.menu.planning',
  },
  {
    path: statusRoute,
    token: 'pages:projects.inspector.menu.status',
    tooltipTranslation: '',
  },
  {
    access: ({isGranted}) => isGranted('FLOW_NODE_READ_GUEST_PERMISSION_ROLE_ASSIGNMENTS', anyNode),
    path: guestUserRoute,
    token: 'guestUser:inspector.tab',
  },
  {
    path: settingsRoute,
    token: 'pages:projects.inspector.menu.settings',
  },
  {
    path: advancedRoute,
    token: 'pages:projects.inspector.menu.advanced',
  },
];

const taskTabs: InspectorTabItem[] = [
  {
    path: editTaskRoute,
    token: 'pages:projects.inspector.menu.general',
  },
  {
    //the task planning inspector also shows the logical dependencies, which are visible with basic read:
    access: () => true,
    path: planningRoute,
    token: 'pages:projects.inspector.menu.planning',
  },
  {
    path: historyRoute,
    token: 'pages:projects.inspector.menu.history',
  },
];
const customerTabs: InspectorTabItem[] = [
  {
    path: customerRoute,
    token: '',
  },
];
const inspectorTabConfig: TabRecords = {
  [EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE]: subWorkPackageTabs,
  [EnumFlowNodeType.VALUE_WORK_PACKAGE]: workPackageTabs,
  [EnumFlowNodeType.VALUE_GROUP]: groupTabs,
  [EnumFlowNodeType.VALUE_PROJECT]: projectTabs,
  [EnumFlowNodeType.VALUE_TASK]: taskTabs,
  flowCustomer: customerTabs,
};

//#endregion

export interface StandardInspectorRoutesOptions {
  actionMenu?: ComponentType;
  useOwnProjectsPid?: boolean;
  defaultRoute?: InspectorRoutes;
  parameterName?: InspectorParameterNames;
}

export function getStandardInspectorRoutes({
  defaultRoute = generalRoute,
  actionMenu,
  useOwnProjectsPid = true,
  parameterName = 'inspectId',
}: StandardInspectorRoutesOptions): RouteObject[] {
  const children = [
    {
      children: [
        advancedRouteObject(),
        billingsRouteObject(),
        boardRouteObject(),
        customerRouteObject(),
        historyRouteObject(),
        editTaskRouteObject(),
        generalRouteObject(),
        guestUserRouteObject(),
        milestonesRouteObject(),
        moreRouteObject(),
        planningRouteObject(),
        responsibilityRouteObject(),
        statusRouteObject(),
        tasksRouteObject(),
        timeAndMoneyRouteObject(),
        timeTrackingRouteObject(),
        settingsRouteObject(),
        {
          element: <Navigate to={defaultRoute} replace />,
          path: '*',
        },
      ],
      element: (
        <StandardInspectorContext parameterName={parameterName}>
          <StandardInspector tabConfig={inspectorTabConfig} actionMenu={actionMenu} />
        </StandardInspectorContext>
      ),
    },
  ];

  if (useOwnProjectsPid) {
    return [
      {
        children,
        path: `:${parameterName}`,
      },
      ...dialogRouteObjects,
    ];
  }
  return children;
}

interface WrappedStandardInspectorRoutesProps {
  children: RouteObject[];
  standardInspectorProps: StandardInspectorProps;
  parameterName?: InspectorParameterNames;
  type?: InspectorContextSelectedTypes;
  contextWrapper?: InspectorContextWrapper;
}

export function getWrappedStandardInspectorRoutes({
  children,
  standardInspectorProps,
  parameterName = 'inspectId',
  type,
  contextWrapper: ContextWrapper = StandardInspectorContext,
}: WrappedStandardInspectorRoutesProps): RouteObject[] {
  return [
    {
      children: [
        {
          children,
          element: (
            <ContextWrapper parameterName={parameterName} type={type}>
              <StandardInspector {...standardInspectorProps} />
            </ContextWrapper>
          ),
        },
      ],
      path: `:${parameterName}`,
    },
    ...dialogRouteObjects,
  ];
}

export function generateStandardInspectorRoute(pidId: Uuid | null | undefined): string;
export function generateStandardInspectorRoute(pidId: Uuid, inspectorPage: InspectorRoutes | undefined): string;
export function generateStandardInspectorRoute(
  pidId: Uuid | null | undefined,
  inspectorPage?: InspectorRoutes | undefined,
): string {
  let path = '';
  if (pidId) {
    path = pidId;
  }
  if (inspectorPage) {
    path += '/' + inspectorPage;
  }
  return path + window.location.search;
}

export function useInspectorId(): Uuid | undefined {
  const {inspectId} = useParams<'inspectId'>();
  return inspectId;
}
