import {getFlowCustomerSelector} from '@octaved/flow/src/Modules/Selectors/CustomerSelectors';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {useIsGranted} from '@octaved/security/src/Authorization/Authorization';
import {Uuid} from '@octaved/typescript/src/lib';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {FramedSectionOrTileProps} from './Type';

export interface CustomerProps {
  customerId: Uuid;
  customerLocationId: Uuid | null;
}

export default function Customer({
  customerId,
  customerLocationId,
  frame: Frame,
}: CustomerProps & FramedSectionOrTileProps): ReactElement | null {
  const {t} = useTranslation();
  const customer = useSelector((state: FlowState) => getFlowCustomerSelector(state)(customerId));
  const location = customer?.locations.find((location) => location.id === customerLocationId);

  if (!useIsGranted('FLOW_GLOBAL_CUSTOMERS_READ')) {
    return null;
  }

  return (
    <Frame labelToken={'nodeDetails:field.customer.label'}>
      <div>
        {customer?.name}
        {customer?.customerNumber ? <> ({customer?.customerNumber})</> : <></>}
        {location && (
          <>
            <br />
            <i>{t('nodeDetails:field.customer.location')}:</i> {location.name}
            {location.number ? <> ({location.number})</> : <></>}
          </>
        )}
      </div>
    </Frame>
  );
}
