import {EnumFlowGroupType, EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {hasFeature} from '@octaved/env/src/Features';
import {isGrantedSelector} from '@octaved/security/src/Authorization/Authorization';
import {NodeRight} from '@octaved/security/src/Authorization/Rights';
import {Uuid} from '@octaved/typescript/src/lib';
import {currentOrgUserIdSelector} from '@octaved/users/src/Selectors/CurrentOrgUserSelectors';
import {
  ArrowRightLeft,
  ArrowUpRight,
  Building,
  Copy,
  Download,
  Edit,
  FolderOpen,
  PlusCircle,
  Shuffle,
  Star,
  Trash,
} from 'lucide-react';
import {useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import {useGenerateDynamicLink} from '../../Components/DynamicLink';
import {createCheckOption} from '../../Components/Form/Dropdown/DropdownPopup/CheckOption';
import {DropdownPopupItemOptions, DropdownPopupItemType} from '../../Components/Form/Dropdown/DropdownPopup/Types';
import {useStartTimeTrackingOption} from '../../Components/TimeRecord/StartTimeTrackingOption';
import {NodeType} from '../../EntityInterfaces/Nodes';
import {subscribeToNode, unsubscribeFromNode} from '../../Modules/FavoriteNodes';
import {useNodeSearch} from '../../Modules/Hooks/NodeSearch';
import {useIsUsingProjectFolders} from '../../Modules/Hooks/ProjectFolders';
import {useCanCreateProjectsSomewhere} from '../../Modules/Projects';
import {manageRights} from '../../Modules/ResponsibleNode';
import {isCustomer} from '../../Modules/Selectors/CustomerSelectors';
import {canCreateGroupSelector, getRootGroupTypeSelector} from '../../Modules/Selectors/GroupSelectors';
import {useNodeAncestry} from '../../Modules/Selectors/NodeTreeSelectors';
import {getProjectForPidSelector} from '../../Modules/Selectors/PidSelectors';
import {
  projectControllingDemoModeSelector,
  projectsSelectedProjectFolderSelector,
} from '../../Modules/Selectors/UiPages/ProjectsSelector';
import {moveGroupOrWorkPackage, rearrangeProject, setProjectControllingDemoMode} from '../../Modules/UiPages/Projects';
import {isGroup, isPid, isPidOrSubWOrkPackage, isProject, isWorkPackage} from '../../Node/NodeIdentifiers';
import {
  changeCustomerRoute,
  changeProjectFolderRoute,
  deletePidDialogRoute,
  duplicatePidDialogRoute,
  exportProjectRoute,
  responsibleBatchToolRoute,
  workPackageStatusBatchToolRoute,
} from '../../Routing/Routes/DialogRoutes';
import {
  useGetNewGroupPidDialogRoute,
  useGetNewProjectDialogRoute,
  useGetNewSubWorkPackageDialogRoute,
  useGetNewWorkPackageDialogRoute,
} from '../../Routing/Routes/Dialogs';

const deleteTranslationToken: {[x: string]: string} = {
  [EnumFlowNodeType.VALUE_WORK_PACKAGE]: 'pages:projects.inspector.deleteWorkPackage',
  [EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE]: 'pages:projects.inspector.deleteSubWorkPackage',
  [EnumFlowNodeType.VALUE_GROUP]: 'pages:projects.inspector.deleteGroup',
  [EnumFlowNodeType.VALUE_PROJECT]: 'pages:projects.inspector.deleteProject',
};
const deleteSprintTranslationToken: {[x: string]: string} = {
  [EnumFlowNodeType.VALUE_WORK_PACKAGE]: 'pages:projects.inspector.deleteUserStory',
  [EnumFlowNodeType.VALUE_GROUP]: 'pages:projects.inspector.deleteSprint',
};

function getDeleteToken(type: EnumFlowNodeType, groupType?: EnumFlowGroupType): string {
  if (groupType === EnumFlowGroupType.VALUE_SPRINT) {
    return deleteSprintTranslationToken[type];
  }
  if (type === EnumFlowNodeType.VALUE_GROUP && groupType === EnumFlowGroupType.VALUE_BACKLOG) {
    return 'pages:projects.inspector.deleteBacklog';
  }

  return deleteTranslationToken[type];
}

interface IdNode {
  id: Uuid | number;
}

interface MenuEntries {
  showChangeCustomer?: boolean;
  showChangeProjectFolder?: boolean;
  showDeleteNode?: boolean;
  showDuplicate?: boolean;
  showExport?: boolean;
  showMarkAsFavorite?: boolean;
  showMove?: boolean;
  showNewGroup?: boolean;
  showNewProject?: boolean;
  showNewWorkpackage?: boolean;
  showRearrange?: boolean;
  showTrackTime?: boolean;
  showProjectControllingDemoMode?: boolean;
}

export function useInspectorSubMenu(
  node: IdNode | undefined | null,
  {
    showChangeCustomer = true,
    showChangeProjectFolder = true,
    showDeleteNode = true,
    showDuplicate = true,
    showExport = true,
    showMarkAsFavorite = true,
    showMove = true,
    showNewGroup = true,
    showNewProject = true,
    showNewWorkpackage = true,
    showRearrange = true,
    showTrackTime = true,
    showProjectControllingDemoMode = true,
  }: MenuEntries = {},
): DropdownPopupItemOptions[] {
  const id = node?.id;
  const linkGenerator = useGenerateDynamicLink();
  const navigate = useNavigate();
  const ancestry = useNodeAncestry(typeof id === 'string' ? id : null, true);
  const parent = ancestry.ancestors[1] as NodeType | undefined;
  const parentNodeId = parent?.id || '';
  const projectFolderId = useSelector(projectsSelectedProjectFolderSelector);
  const isGranted = useSelector(isGrantedSelector);
  const canCreateProjectsSomewhere = useCanCreateProjectsSomewhere();
  const dispatch = useDispatch();
  const getProjectForPid = useSelector(getProjectForPidSelector);
  const isUsingProjectFolders = useIsUsingProjectFolders();
  const canCreateGroup = useSelector(canCreateGroupSelector);
  const getRotGroupType = useSelector(getRootGroupTypeSelector);
  const groupType = id ? getRotGroupType(id.toString()) : undefined;
  const getNewWorkpackageDialogRoute = useGetNewWorkPackageDialogRoute();
  const getNewSubWorkpackageDialogRoute = useGetNewSubWorkPackageDialogRoute();
  const getNewProjectDialogRoute = useGetNewProjectDialogRoute();
  const getNewGroupPidDialogRoute = useGetNewGroupPidDialogRoute();
  const timeTrackingOption = useStartTimeTrackingOption('', id ? id.toString() : undefined);
  const {pathname} = useLocation();
  const currentUserId = useSelector(currentOrgUserIdSelector);
  const {nodeIds: favoriteNodeIds} = useNodeSearch('favorite', currentUserId);
  const isFavorite = useMemo(() => typeof id === 'string' && favoriteNodeIds.includes(id), [favoriteNodeIds, id]);

  return useMemo<DropdownPopupItemOptions[]>(() => {
    const groups: DropdownPopupItemOptions[][] = [];

    const groupCreate: DropdownPopupItemOptions[] = [];
    groups.push(groupCreate);

    if (
      showNewProject &&
      (isCustomer(node) || isProject(node)) &&
      isGranted('FLOW_NODE_PID_MANAGE_BASIC', projectFolderId)
    ) {
      groupCreate.push({
        icon: <PlusCircle />,
        iconColor: 'darkGrey',
        onMouseDown: () => navigate(getNewProjectDialogRoute(isProject(node) ? node.id : undefined)),
        token: 'general:newProject',
        type: DropdownPopupItemType.item,
      });
    }

    if (showNewGroup && (isProject(node) || isGroup(node)) && canCreateGroup(node.id)) {
      groupCreate.push({
        icon: <PlusCircle />,
        iconColor: 'darkGrey',
        onMouseDown: () => navigate(getNewGroupPidDialogRoute(node.id)),
        token: 'pages:projects.inspector.newGroup',
        type: DropdownPopupItemType.item,
      });
    }

    if (
      showNewWorkpackage &&
      isPid(node) &&
      !(isWorkPackage(node) ? parent : node)?.isArchived &&
      isGranted('FLOW_NODE_PID_MANAGE_BASIC', isWorkPackage(node) ? parentNodeId : node.id)
    ) {
      groupCreate.push({
        icon: <PlusCircle />,
        iconColor: 'darkGrey',
        onMouseDown: () => navigate(getNewWorkpackageDialogRoute(node.id)),
        token: 'pages:projects.inspector.newWorkPackage',
        type: DropdownPopupItemType.item,
      });
    }

    if (
      hasFeature('subWorkPackages') &&
      showNewWorkpackage &&
      isWorkPackage(node) &&
      !node.isArchived &&
      isGranted('FLOW_NODE_SUB_WORK_PACKAGE_MANAGE_BASIC', node.id)
    ) {
      groupCreate.push({
        icon: <PlusCircle />,
        iconColor: 'darkGrey',
        onMouseDown: () => navigate(getNewSubWorkpackageDialogRoute(node.id)),
        token: 'pages:projects.inspector.newSubWorkPackage',
        type: DropdownPopupItemType.item,
      });
    }

    if (showTrackTime && isWorkPackage(node) && !node.isLocked && !node.isArchived && !timeTrackingOption.hidden) {
      groups.push([timeTrackingOption]);
    }

    const groupArrange: DropdownPopupItemOptions[] = [];
    groups.push(groupArrange);

    if (showRearrange && isPid(node) && !node.isArchived && isGranted('FLOW_NODE_PID_MANAGE_BASIC', parentNodeId)) {
      groupArrange.push({
        icon: <Shuffle />,
        iconColor: 'darkGrey',
        onMouseDown: () => {
          const project = getProjectForPid(node.id);
          if (project) {
            dispatch(rearrangeProject(project.id));
          }
        },
        token: 'pages:projects.inspector.header.rearrange',
        type: DropdownPopupItemType.item,
      });
    }

    if (
      showDuplicate &&
      isPid(node) &&
      isGranted('FLOW_NODE_PID_COPY', node.id, 'allInTree+self') &&
      //The copy dialog supports selecting a different project folder for new projects, so we don't need to check the parent permissions here:
      ((isProject(node) && canCreateProjectsSomewhere) ||
        (parent?.isArchived === false && isGranted('FLOW_NODE_PID_MANAGE_BASIC', parentNodeId)))
    ) {
      groupArrange.push({
        icon: <Copy />,
        iconColor: 'darkGrey',
        onMouseDown: () => navigate(linkGenerator('dialog', `${duplicatePidDialogRoute}/${node.id}`)),
        token: isWorkPackage(node)
          ? 'pages:projects.inspector.header.duplicateWorkPackage'
          : 'pages:projects.inspector.header.duplicateProject',
        type: DropdownPopupItemType.item,
      });
    }

    if (showExport && isProject(node) && isGranted('FLOW_NODE_PROJECT_EXPORT', node.id, 'allInTree+self')) {
      groupArrange.push({
        icon: <Download />,
        iconColor: 'darkGrey',
        onMouseDown: () => navigate(linkGenerator('dialog', `${exportProjectRoute}/${node.id}`)),
        token: 'pages:projects.inspector.header.exportProject',
        type: DropdownPopupItemType.item,
      });
    }

    const groupChange: DropdownPopupItemOptions[] = [];
    groups.push(groupChange);

    if (
      showMove &&
      (isGroup(node) || isWorkPackage(node)) &&
      !node.isArchived &&
      isGranted('FLOW_NODE_PID_MANAGE_BASIC', node.id)
    ) {
      groupChange.push({
        icon: <ArrowUpRight />,
        iconColor: 'darkGrey',
        onMouseDown: () => dispatch(moveGroupOrWorkPackage(node.id)),
        token: 'pages:projects.inspector.header.move',
        type: DropdownPopupItemType.item,
      });
    }

    if (isProject(node) && !node.isArchived && isGranted('FLOW_NODE_PID_MANAGE_BASIC', node.id)) {
      if (showChangeProjectFolder && isUsingProjectFolders) {
        groupChange.push({
          icon: <FolderOpen />,
          iconColor: 'darkGrey',
          onMouseDown: () => navigate(linkGenerator('dialog', `${changeProjectFolderRoute}/${node.id}`)),
          token: 'pages:projects.inspector.header.changeProjectFolder',
          type: DropdownPopupItemType.item,
        });
      }
      if (showChangeCustomer) {
        groupChange.push({
          icon: <Building />,
          iconColor: 'darkGrey',
          onMouseDown: () => navigate(linkGenerator('dialog', `${changeCustomerRoute}/${node.id}`)),
          token: 'pages:projects.inspector.header.changeCustomer',
          type: DropdownPopupItemType.item,
        });
      }
    }

    const groupBulkActions: DropdownPopupItemOptions[] = [];
    groups.push(groupBulkActions);

    if (
      (isProject(node) || isGroup(node)) &&
      !node.isArchived &&
      Object.values<NodeRight>(manageRights).every((right) => isGranted(right, node.id, 'allInTree+self'))
    ) {
      groupBulkActions.push({
        icon: <ArrowRightLeft />,
        iconColor: 'darkGrey',
        onMouseDown: () => navigate(linkGenerator('dialog', `${responsibleBatchToolRoute}/${node.id}`)),
        token: 'dialogs:responsibleBatchTool.inspectorMenu.link',
        type: DropdownPopupItemType.item,
      });
    }

    if (
      (isProject(node) || isGroup(node)) &&
      !node.isArchived &&
      isGranted('FLOW_NODE_PID_MANAGE_BASIC', node.id, 'allInTree+self')
    ) {
      groupBulkActions.push({
        icon: <Edit />,
        iconColor: 'darkGrey',
        onMouseDown: () => navigate(linkGenerator('dialog', `${workPackageStatusBatchToolRoute}/${node.id}`)),
        token: 'pages:projects.inspector.header.applyWorkPackageStatusToSubtree',
        type: DropdownPopupItemType.item,
      });
    }

    if (groupBulkActions.length) {
      groupBulkActions.unshift({
        token: 'pages:projects.inspector.header.bulkActions',
        type: DropdownPopupItemType.header,
      });
    }

    if (showMarkAsFavorite && (isWorkPackage(node) || isProject(node))) {
      groups.push([
        {
          icon: <Star fill={isFavorite ? '#f59e0b' : '#fff'} stroke={isFavorite ? '#f59e0b' : '#64748b'} />,
          onMouseDown: () => {
            if (isFavorite) {
              dispatch(unsubscribeFromNode(node.id));
            } else {
              dispatch(subscribeToNode(node.id));
            }
          },
          token: 'pages:projects.inspector.header.favorite',
          type: DropdownPopupItemType.item,
        },
      ]);
    }

    const groupDelete: DropdownPopupItemOptions[] = [];
    groups.push(groupDelete);

    if (
      showDeleteNode &&
      isPidOrSubWOrkPackage(node) &&
      isGranted('FLOW_NODE_PID_MANAGE_BASIC', node.id, 'allInTree+self')
    ) {
      groupDelete.push({
        className: 'hover:!bg-red-50',
        icon: <Trash />,
        iconColor: 'red',
        onMouseDown: () => navigate(linkGenerator('dialog', `${deletePidDialogRoute}/${node.id}`)),
        textColor: 'red',
        token: getDeleteToken(node.nodeType, groupType),
        type: DropdownPopupItemType.item,
      });
    }

    if (showProjectControllingDemoMode && pathname.startsWith('/projectControlling')) {
      groups.push([
        createCheckOption(
          projectControllingDemoModeSelector,
          (demoMode) => setProjectControllingDemoMode(demoMode),
          'pages:projects.inspector.demoMode',
        ),
      ]);
    }

    return groups.reduce<DropdownPopupItemOptions[]>((acc, group) => {
      if (acc.length && group.length) {
        acc.push({type: DropdownPopupItemType.divider});
      }
      if (group.length) {
        acc.push(...group);
      }
      return acc;
    }, []);
  }, [
    canCreateGroup,
    canCreateProjectsSomewhere,
    dispatch,
    getNewGroupPidDialogRoute,
    getNewProjectDialogRoute,
    getNewSubWorkpackageDialogRoute,
    getNewWorkpackageDialogRoute,
    getProjectForPid,
    groupType,
    isFavorite,
    isGranted,
    isUsingProjectFolders,
    linkGenerator,
    navigate,
    node,
    parent,
    parentNodeId,
    pathname,
    projectFolderId,
    showChangeCustomer,
    showChangeProjectFolder,
    showDeleteNode,
    showDuplicate,
    showExport,
    showMarkAsFavorite,
    showMove,
    showNewGroup,
    showNewProject,
    showNewWorkpackage,
    showProjectControllingDemoMode,
    showRearrange,
    showTrackTime,
    timeTrackingOption,
  ]);
}
