import {ReactElement} from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Group, Project} from '../../EntityInterfaces/Pid';
import {useLoadNode} from '../../Modules/Hooks/Nodes';
import {isPid, isWorkPackage} from '../../Node/NodeIdentifiers';
import {getNodeSelector} from '../../Modules/Selectors/NodeSelectors';
import {getParentPidSelector} from '../../Modules/Selectors/PidSelectors';
import NewWorkPackageFormBody from './NewWorkPackageFormBody';

export default function NewWorkPackageForm({
  relativeNodeId,
  close,
}: {
  relativeNodeId?: string;
  close?: () => void;
}): ReactElement | null {
  const newWpRelativeNodeId = useParams<'newWpRelativeNodeId'>().newWpRelativeNodeId || relativeNodeId;
  useLoadNode(newWpRelativeNodeId);
  const relative = useSelector(getNodeSelector)(newWpRelativeNodeId);
  const getParentPid = useSelector(getParentPidSelector);
  if (!relative) {
    return null;
  }
  if (!isPid(relative)) {
    throw new Error('Invalid relative node to create work package');
  }
  const parent = isWorkPackage(relative) ? getParentPid(relative.id) : relative;
  if (!parent) {
    return null;
  }
  return <NewWorkPackageFormBody parent={parent as Project | Group} relative={relative} close={close} />;
}
