import {Uuid} from '@octaved/typescript/src/lib';
import {Clock} from 'lucide-react';
import {useDispatch, useSelector} from 'react-redux';
import {useTimeTrackingSelector} from '../../Modules/Selectors/SettingSelectors';
import {canStartTimeRecordOnNodeSelector} from '../../Modules/Selectors/TimeRecordingValidationSelectors';
import {createInspectorPatch, setUiState} from '../../Modules/Ui';
import {DropdownPopupItem, DropdownPopupItemType} from '../Form/Dropdown/DropdownPopup/Types';

export function useStartTimeTrackingOption(message: string, nodeId?: Uuid, hidden?: boolean): DropdownPopupItem {
  const usingTimeTracking = useSelector(useTimeTrackingSelector);
  const dispatch = useDispatch();
  const canStartTimeRecordSelector = useSelector(canStartTimeRecordOnNodeSelector);
  const canStartTimeRecord = !nodeId || canStartTimeRecordSelector(nodeId);

  return {
    hidden: !canStartTimeRecord || !usingTimeTracking || hidden,
    icon: <Clock />,
    iconColor: 'darkGrey',
    onMouseDown: () => {
      dispatch(
        setUiState(
          createInspectorPatch({
            editTimeRecord: {
              message,
              referenceNode: nodeId,
              type: 'create',
            },
          }),
        ),
      );
    },
    token: 'pages:projects.inspector.startTimeTracking',
    type: DropdownPopupItemType.item,
  };
}
