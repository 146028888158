import CaretDown from '@fortawesome/fontawesome-pro/svgs/solid/caret-down.svg';
import CaretRight from '@fortawesome/fontawesome-pro/svgs/solid/caret-right.svg';
import {Uuid} from '@octaved/typescript/src/lib';
import {IconButton} from '@octaved/ui';
import {useDarkenColor, useHexContrast} from '@octaved/ui-components/src/Hooks/UseContrastColor';
import SlideToggleElement from '@octaved/ui-components/src/SlideToggle/SlideToggleElement';
import {currentOrgUserIdSelector} from '@octaved/users/src/Selectors/CurrentOrgUserSelectors';
import {SimpleUnitType} from '@octaved/users/src/UnitType';
import classNames from 'classnames';
import {forwardRef, MutableRefObject, ReactElement, ReactNode, useEffect, useMemo, useRef} from 'react';
import {useSelector} from 'react-redux';
import {taskListSectionColorSelector} from '../../../Modules/Selectors/Ui/TaskListSelectors';
import {useShowTaskGroup} from '../../../Modules/Ui/TaskList';
import BaseFlowAvatar from '../../Avatar/BaseFlowAvatar';
import {DropdownPopupItemOptions} from '../../Form/Dropdown/DropdownPopup/Types';
import {useTaskListContext} from '../TaskListContext';
import {useStaticDragContext} from '../TaskListForNode/DragAndDrop/DragContext';
import DragHandle from '../TaskListForNode/DragAndDrop/DragHandle';
import {dummyRef, TaskGroupContext, taskGroupContext, useTaskGroupContext} from './TaskGroupContext';
import TaskGroupMenu from './TaskGroupMenu';

export interface TaskGroupProps {
  alignTop?: boolean;
  children?: ReactNode;
  dragRef?: MutableRefObject<HTMLDivElement | null>;
  dropRef?: MutableRefObject<HTMLDivElement | null>;
  id: Uuid;
  isDragging?: boolean;
  name: ReactNode;
  topLevelUserNode?: boolean;
  useMenuOptions?: () => DropdownPopupItemOptions[];
}

export default forwardRef<HTMLDivElement, TaskGroupProps>(function TaskGroup(
  {alignTop, children, dragRef, dropRef, id, isDragging, name, topLevelUserNode, useMenuOptions},
  ref,
): ReactElement {
  const {focusNewTaskRefs} = useTaskListContext();
  const {focusNewTaskSection: parentFocusNewTaskSection} = useTaskGroupContext();
  const {show, toggle} = useShowTaskGroup(id);
  const focusNewTask = useRef<() => void>();
  const focusNewTaskSection = useRef<() => void>();
  const ctx = useMemo(
    (): TaskGroupContext => ({
      focusNewTask,
      id,
      focusNewTaskSection: parentFocusNewTaskSection !== dummyRef ? parentFocusNewTaskSection : focusNewTaskSection,
    }),
    [id, parentFocusNewTaskSection],
  );

  useEffect(() => {
    focusNewTaskRefs[id] = focusNewTask;
    return () => {
      if (focusNewTaskRefs[id] === focusNewTask) {
        focusNewTaskRefs[id] = undefined;
      }
    };
  }, [focusNewTaskRefs, id]);

  const {isSet} = useStaticDragContext();
  const sectionColors = useSelector(taskListSectionColorSelector);
  const hasIndividualColor = Boolean(sectionColors[id]);
  const bottomColor = sectionColors[id] ?? '#FFF';
  const topColor = useDarkenColor(bottomColor, 5);
  const gradient = `linear-gradient(0deg, ${topColor} 0%, ${bottomColor} 100%)`;
  const contrastColor = useHexContrast(bottomColor);
  const currentUserId = useSelector(currentOrgUserIdSelector);

  return (
    <taskGroupContext.Provider value={ctx}>
      <div className={classNames('taskGroup', {alignTop, isDragging, topLevelUserNode, collapsed: !show})} ref={ref}>
        <div className={classNames('groupHeader', {hasDragRef: !!dragRef})} ref={dropRef}>
          {!!dragRef && isSet && <DragHandle dragRef={dragRef} />}
          <IconButton variant={'ghost'} icon={show ? <CaretDown /> : <CaretRight />} size={'xs'} onClick={toggle} />

          {topLevelUserNode && <BaseFlowAvatar id={currentUserId} type={SimpleUnitType.user} size={'small'} />}

          <div className={'groupName'}>{name}</div>

          <div className={'headerOptions'}>
            {useMenuOptions && <TaskGroupMenu useMenuOptions={useMenuOptions} iconColor={'darkGrey'} />}
          </div>
        </div>

        <SlideToggleElement visible={show}>
          <div className={'contents'}>{children}</div>
        </SlideToggleElement>
      </div>

      {/*#region styles*/}
      {/*language=scss*/}
      <style jsx>{`
        .taskGroup {
          border-radius: 8px;
          background: #fff;
          border: 1px solid #cccccc;
        }

        :global(.taskGroup) .taskGroup {
          border: none;
          background: none;
          border-radius: 0;
          margin-top: 10px;

          & .groupHeader {
            border-top: 1px solid #e2e8f0;
            border-radius: 0;
            padding: 0 8px 0 22px;

            & :global(svg),
            & :global(svg path) {
              color: ${hasIndividualColor ? contrastColor : '#595E65'} !important;
              fill: ${hasIndividualColor ? contrastColor : '#595E65'} !important;
            }
          }
        }

        .taskGroup.isDragging {
          opacity: 0.2;
        }

        .topLevelUserNode {
          border-radius: 4px;
          border-left: 4px solid #666;
        }

        .topLevelUserNode.collapsed {
          border-radius: 4px;
        }

        .groupName {
          font-weight: 500;
          color: ${hasIndividualColor ? contrastColor : '#333'};
        }

        .groupHeader {
          padding: 6px 8px;
          display: flex;
          grid-gap: 4px;
          align-items: center;
          line-height: 16px;
          border-radius: 4px;
          color: ${hasIndividualColor ? contrastColor : '#333'};
          background: ${hasIndividualColor ? gradient : '#ECF0F0'};

          .alignTop & {
            align-items: flex-start;
          }

          &.hasDragRef {
            padding-left: var(--drag-handle-width);
            position: relative;

            &:hover {
              --drag-handle-visible: 1;
            }
          }

          &:hover .headerOptions {
            visibility: visible;
            pointer-events: all;
          }

          .topLevelUserNode.collapsed > & {
            border-radius: 4px;
            transition: border-radius 170ms ease-in-out;
            border-bottom: none;
          }

          .topLevelUserNode > & {
            background: #fff;
            border-bottom: 1px solid #ccc;
            border-radius: 4px 4px 0 0;
          }
        }

        .headerOptions {
          visibility: hidden;
          pointer-events: none;
        }
      `}</style>
      {/*#endregion*/}
    </taskGroupContext.Provider>
  );
});
