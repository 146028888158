import {IconButton, IconColor} from '@octaved/ui';
import {MoreHorizontal} from 'lucide-react';
import {ReactElement} from 'react';
import DropdownPopup from '../../Form/Dropdown/DropdownPopup';
import {DropdownPopupItemOptions} from '../../Form/Dropdown/DropdownPopup/Types';

interface Props {
  useMenuOptions: () => DropdownPopupItemOptions[];
  iconColor?: IconColor;
}

export default function TaskGroupMenu({iconColor = 'darkGrey', useMenuOptions}: Props): ReactElement | null {
  const menuOptions = useMenuOptions();
  if (!menuOptions.length) {
    return null;
  }
  return (
    <DropdownPopup items={menuOptions} position={'bottom right'} closeOnSelect inDrawer>
      <IconButton variant={'ghost'} icon={<MoreHorizontal />} size={'sm'} iconColor={iconColor} />
    </DropdownPopup>
  );
}
