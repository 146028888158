import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {toChildren} from '@octaved/node-search/src/Factories/Tree';
import {Button, TWInput} from '@octaved/ui';
import ErrorList from '@octaved/ui-components/src/React/Formik/ErrorList';
import {useFormik} from 'formik';
import {ReactElement, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import * as Yup from 'yup';
import {WorkPackage} from '../../EntityInterfaces/Pid';
import {SubWorkPackage} from '../../EntityInterfaces/SubWorkPackage';
import {useCloseAndSelectNewNode} from '../../Hooks/CloseNodeCreationDialog';
import {useLoadedNodes} from '../../Modules/Hooks/Nodes';
import {useCombinedNodeSearch} from '../../Modules/Hooks/NodeSearch';
import {createSubWorkPackage, createSubWorkPackageEntity} from '../../Modules/SubWorkPackages';
import {isSubWorkPackage} from '../../Node/NodeIdentifiers';

function useSiblings(workPackage: WorkPackage): ReadonlyArray<SubWorkPackage> {
  const {nodeIds} = useCombinedNodeSearch({
    and: [toChildren(workPackage.id), ['nodeType', EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE]],
  });
  const {nodes} = useLoadedNodes<SubWorkPackage>(nodeIds);
  return useMemo(() => [...nodes].sort((a, b) => a.sortOrder - b.sortOrder), [nodes]);
}

interface Props {
  relativeNode: WorkPackage | SubWorkPackage;
  workPackage: WorkPackage;
}

export default function NewSubWorkPackageForm({relativeNode, workPackage}: Props): ReactElement {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const siblings = useSiblings(workPackage);
  const close = useCloseAndSelectNewNode();
  const formik = useFormik({
    initialValues: createSubWorkPackageEntity(),
    onSubmit: async (values) => {
      const siblingIds = siblings.map(({id}) => id);
      const position = isSubWorkPackage(relativeNode) ? siblingIds.indexOf(relativeNode.id) : siblingIds.length - 1;
      siblingIds.splice(position + 1, 0, values.id);
      await dispatch(createSubWorkPackage(values, workPackage.id, siblingIds));
      close(values.id, EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE);
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().min(1).max(191).required(),
    }),
  });
  return (
    <form onSubmit={formik.handleSubmit} className={'mb-6 flex flex-col gap-y-6'}>
      <ErrorList formik={formik} />
      <TWInput
        label={t('dialogs:createSubWorkpackage.name')}
        name={'name'}
        onChange={formik.handleChange}
        required
        value={formik.values.name}
      />
      <Button color={'blue'} fluid isDisabled={formik.isSubmitting} loading={formik.isSubmitting} type={'submit'}>
        {t('general:create')}
      </Button>
    </form>
  );
}
