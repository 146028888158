import {Project} from '@octaved/flow/src/EntityInterfaces/Pid';
import {ReactElement} from 'react';
import Section from '../../../../Section';
import Customer from '../../../Common/Customer';

interface ProjectCustomerSectionProps {
  node: Project;
}

export default function ProjectCustomerSection({node}: ProjectCustomerSectionProps): ReactElement {
  return <Customer frame={Section} customerId={node.flowCustomer} customerLocationId={node.customerLocation} />;
}
