import {ReactElement} from 'react';
import DialogAndDrawerHeader from '../../Components/Layout/DialogAndDrawerHeader';
import {DialogContent, DialogFrame, DialogTitle} from '../DialogFrame';
import NewGroupForm from './NewGroupForm';

export default function NewGroup({
  relativeNodeId,
  close,
}: {
  relativeNodeId?: string;
  close?: () => void;
}): ReactElement | null {
  return (
    <DialogFrame size={'mini'} overflowVisible onClose={close}>
      <DialogTitle onClose={close}>
        <DialogAndDrawerHeader header={'dialogs:createGroup.title'} />
      </DialogTitle>
      <DialogContent>
        <NewGroupForm relativeNodeId={relativeNodeId} />
      </DialogContent>
    </DialogFrame>
  );
}
