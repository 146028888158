import {EnumFlowTaskStatus} from '@octaved/env/src/dbalEnumTypes';
import {Uuid} from '@octaved/typescript/src/lib';
import {formatDecimal} from '@octaved/users/src/Culture/NumberFormatter';
import classNames from 'classnames';
import {memo, MouseEvent, MutableRefObject, ReactElement, useCallback, useMemo} from 'react';
import {useDispatch} from 'react-redux';
import {TaskPatchData} from '../../../../EntityInterfaces/Task';
import {patchTaskWithTaskPatchData} from '../../../../Modules/Tasks';
import {useInspectorId} from '../../../../Routing/Routes/Inspectors/StandardInspector';
import {useTaskListContext} from '../../TaskListContext';
import {useTaskListReadonlyContext} from '../../TaskListReadonlyContext';
import {OnEnterKeyDown} from '../EditName';
import {useNameSnapshot} from '../NameSnapshot';
import {ShallowTask} from './ShallowTask';
import AssignmentCell from './TaskCell/AssignmentCell';
import LabelCell from './TaskCell/LabelCell';
import NameCell from './TaskCell/NameCell';
import TaskDescription from './TaskCell/NameCell/TaskDescription';
import PlanningCell from './TaskCell/PlanningCell';
import ShowSubTaskToggle from './TaskCell/ShowSubTaskToggle';
import TimeDurationCell from './TaskCell/TimeDurationCell';
import TaskRowSubMenu from './TaskRowSubMenu';

export interface TaskRowProps {
  depth: number;
  dragRef: MutableRefObject<HTMLDivElement | null>;
  dropRef: MutableRefObject<HTMLDivElement | null>;
  hasSiblingsWithSubTasks: boolean;
  hasSubTasks: boolean;
  previousSiblingId: Uuid | undefined;
  setShowNewSiblingTask: (show: null | 'prev' | 'next') => void;
  showSubTasks: boolean;
  task: ShallowTask;
  toggleSubTasks: (event?: MouseEvent) => void;
}

export default memo(function TaskRow({
  dragRef,
  dropRef,
  depth,
  hasSiblingsWithSubTasks,
  hasSubTasks,
  setShowNewSiblingTask,
  showSubTasks,
  previousSiblingId,
  task,
  toggleSubTasks,
}: TaskRowProps): ReactElement | null {
  const {readonlyOrNotManageable} = useTaskListReadonlyContext();
  const {displayDoneTasksAsOpen, selectTaskId, selectedTaskId, showNotes, visibleColumns} = useTaskListContext();
  const inspectorId = useInspectorId();
  const selected = task.id === (typeof selectedTaskId !== 'undefined' ? selectedTaskId : inspectorId);
  const dispatch = useDispatch();

  const patch = useCallback(
    (data: Partial<TaskPatchData>) => {
      dispatch(patchTaskWithTaskPatchData(task.id, data));
    },
    [dispatch, task.id],
  );

  const onTaskRowClick = useMemo(
    () => (selectTaskId ? () => selectTaskId(task.id) : undefined),
    [selectTaskId, task.id],
  );

  const name = useNameSnapshot(task, patch);

  const onEnterKeyDown = useCallback<OnEnterKeyDown>(
    ({blur}) => {
      blur();
      setShowNewSiblingTask('next');
    },
    [setShowNewSiblingTask],
  );

  return (
    <div
      className={classNames('taskRow', {
        selected,
        done: !displayDoneTasksAsOpen && task.status === EnumFlowTaskStatus.VALUE_COMPLETE,
        draggable: !readonlyOrNotManageable,
        readonly: readonlyOrNotManageable,
      })}
      ref={dropRef}
      onClick={onTaskRowClick}
      data-id={task.id}
    >
      <NameCell
        depth={depth}
        dragRef={dragRef}
        name={name.value}
        onBlur={name.commit}
        onEnterKeyDown={onEnterKeyDown}
        patch={patch}
        patchName={name.patch}
        note={
          showNotes && (!readonlyOrNotManageable || !!task.description) ? (
            <TaskDescription
              description={task.description || ''}
              patch={patch}
              taskName={task.name || ''}
              taskId={task.id}
            />
          ) : undefined
        }
        rightStruct={
          <TaskRowSubMenu
            task={task}
            depth={depth}
            previousSiblingId={previousSiblingId}
            setShowNewSiblingTask={setShowNewSiblingTask}
          />
        }
        task={task}
        isSingleColumn={visibleColumns.size === 0}
        toggle={
          hasSiblingsWithSubTasks && (
            <ShowSubTaskToggle
              hasSubTasks={hasSubTasks}
              toggleShowSubTasks={toggleSubTasks}
              showSubTasks={showSubTasks}
            />
          )
        }
      />

      <div className={'rightStructure'}>
        {visibleColumns.has('effort') && (
          <TimeDurationCell plannedTime={formatDecimal(task.plannedTime)} patch={patch} />
        )}
        {visibleColumns.has('planing') && <PlanningCell task={task} />}
        {visibleColumns.has('assignments') && <AssignmentCell task={task} />}
        {visibleColumns.has('labels') && <LabelCell labels={task.labels} patch={patch} id={task.id} />}
      </div>

      {/*#region styles*/}
      {/*language=scss*/}
      <style jsx>{`
        .taskRow {
          height: var(--row-height);
          transition: background-color 170ms ease-in-out;
          margin: -1px 0;
          position: relative;

          &.draggable:hover {
            --drag-handle-visible: 1;
          }
        }

        .taskRow,
        .rightStructure {
          display: flex;
          align-items: stretch;
        }

        .rightStructure {
          flex-shrink: 0;
        }

        .taskRow:not(.readonly):hover {
          background-color: var(--color-hover-bg);
        }

        .taskRow.selected,
        .taskRow.selected:hover {
          background-color: var(--color-selectedBg);
        }

        .rightStructure {
          padding-right: var(--drag-handle-width);
        }

        .taskRow.done:not(:hover) :global(.cell) {
          text-decoration: line-through;
        }
      `}</style>
      {/*#endregion*/}
    </div>
  );
});
