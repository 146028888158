import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import NodeName from '@octaved/flow/src/Components/Node/NodeName';
import StringPath from '@octaved/flow/src/Components/Node/StringPath';
import {DEFAULT_INSPECTOR_WIDTH} from '@octaved/flow/src/Drawer/StandardInspector/StandardInspectorPage';
import {isPid, isSubWorkPackage, isTask} from '@octaved/flow/src/Node/NodeIdentifiers';
import {cn, tableGridCellVariant} from '@octaved/ui';
import {ReactElement} from 'react';
import {PlanningDependency} from '../../../../EntityInterfaces/PlanningDependency';
import {DependencyListRowProps} from './DependencyList';
import DependencyTypeSeletion from './DependencyTypeSelection';
import Offset from './Offset';

export default function SuccessorDependencyListRow({
  node,
  dependency,
  readonly,
}: DependencyListRowProps<PlanningDependency>): ReactElement | null {
  if (!isPid(node) && !isTask(node) && !isSubWorkPackage(node)) {
    return null;
  }
  return (
    <>
      <div
        className={cn(tableGridCellVariant({position: 'left', className: 'flex flex-col justify-between'}))}
        style={{maxWidth: `calc(${DEFAULT_INSPECTOR_WIDTH} - 200px)`}}
      >
        <div className={'truncate'}>
          <NodeName nodeId={dependency.successor} />
        </div>
        {node.nodeType !== EnumFlowNodeType.VALUE_TASK && (
          <div className={'text-sm text-slate-600'}>
            <StringPath nodeId={dependency.successor} excludeSelf />
          </div>
        )}
      </div>
      <div className={cn(tableGridCellVariant())}>
        <DependencyTypeSeletion dependency={dependency} node={node} readonly={readonly} />
      </div>
      <Offset dependency={dependency} node={node} readonly={readonly} />
      <div />
    </>
  );
}
