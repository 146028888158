import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {IconButton} from '@octaved/ui';
import classNames from 'classnames';
import {CheckCircle, XCircle} from 'lucide-react';
import {ReactElement, useContext} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {Message} from 'semantic-ui-react';
import css from 'styled-jsx/css';
import {Group, WorkPackage} from '../../../../EntityInterfaces/Pid';
import {setInspector} from '../../../../Modules/Ui';
import {flowStyleContext} from '../../../../Styles/StyleContext';
import LogoSvg from '../../../../svgs/logo.svg?tsx';

//#region <styles>
/*language=SCSS*/
const {className, styles} = css.resolve`
  .message {
    margin: 0 5rem;
  }
`;
//#endregion

const headerTranslationToken = {
  [EnumFlowNodeType.VALUE_GROUP]: 'pages:projects.inspector.header.movePid.moveGroup',
  [EnumFlowNodeType.VALUE_WORK_PACKAGE]: 'pages:projects.inspector.header.movePid.moveWorkpackage',
};

interface Props {
  pid: Group | WorkPackage;
  hasChanges: boolean;
  priceCategoryIssue: boolean;
  hasPlanningDependencyIssue: boolean;

  save?(): Promise<void>;
}

export default function Header({
  pid,
  save,
  hasChanges,
  priceCategoryIssue,
  hasPlanningDependencyIssue,
}: Props): ReactElement {
  const {t} = useTranslation();
  const {
    COLORS: {BORDER},
  } = useContext(flowStyleContext);
  const dispatch = useDispatch();

  return (
    <div className={'header'}>
      <div className={'leftHeader'}>
        <div className={'logo'}>
          <LogoSvg />
        </div>
        <div className={'headerTitle'}>
          <span>
            <Trans i18nKey={headerTranslationToken[pid.nodeType]} />
          </span>
        </div>
      </div>

      {priceCategoryIssue && (
        <Message error visible className={classNames(className, 'message')}>
          <div>
            {pid.nodeType === EnumFlowNodeType.VALUE_WORK_PACKAGE && (
              <span>{t('pages:projects.drawer.movePid.priceCategoryIssue')}</span>
            )}
            {pid.nodeType === EnumFlowNodeType.VALUE_GROUP && (
              <span>{t('pages:projects.drawer.movePid.priceCategoryIssueGroup')}</span>
            )}
          </div>
        </Message>
      )}
      {hasPlanningDependencyIssue && (
        <Message warning visible className={classNames(className, 'message')}>
          <div>
            <span>{t('pages:projects.drawer.movePid.planningDependencyIssue')}</span>
          </div>
        </Message>
      )}

      <div className={'rightHeader'}>
        <IconButton onClick={() => dispatch(setInspector({}))} icon={<XCircle />} label={'general:cancel'} />
        <div className={'spacer'} />
        <IconButton
          onClick={() => {
            if (save) {
              save();
              dispatch(setInspector({}));
            }
          }}
          icon={<CheckCircle />}
          label={'general:save'}
          isDisabled={!hasChanges || priceCategoryIssue}
        />
      </div>

      {/*language=SCSS*/}
      <style jsx>{`
        .header {
          min-height: 50px;
          border-bottom: 1px solid ${BORDER.WEAK};
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          padding: 20px;
          flex-shrink: 0;
        }

        .headerTitle {
          margin-left: 10px;
        }

        .logo {
          width: 34px;
          overflow: hidden;
        }

        .logo > :global(svg) {
          height: 24px;
          margin-left: -10px;
        }
        .leftHeader {
          display: flex;
          align-items: center;
          font-size: 16px;
          flex-shrink: 0;
        }

        .rightHeader {
          display: flex;
          align-items: center;
          flex-shrink: 0;
        }

        .spacer {
          margin: 0 0.4375rem;
        }
      `}</style>
      {styles}
    </div>
  );
}
