import {HStack} from '@octaved/ui';
import {memo, ReactElement, ReactNode} from 'react';

interface Props {
  children: ReactNode;
}

export default memo(function InspectorButtonFooterGrid({children}: Props): ReactElement {
  return (
    <HStack spacing={'15px'} justifyContent={'center'}>
      {children}
    </HStack>
  );
});
