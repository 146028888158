import DependencyModal from '@octaved/planning/src/Components/AddDependencyModal/DependencyModal';
import {ReactElement} from 'react';
import {ShallowTask} from './ShallowTask';
import {useLogicalDepndencyModalForTask} from './TaskCell/LogicalDependency/LogicalDependency';

interface Props {
  onClose: () => void;
  task: ShallowTask;
}

export default function AddLogicalDependency({task, onClose}: Props): ReactElement {
  const logicalDependencyModalProps = useLogicalDepndencyModalForTask(task.id, task.planningLogicalPredecessors);

  return (
    <DependencyModal
      dialogHeaderToken={'pages:projects.inspector.manage.planning.newDependencyPredecessor'}
      dialogInfo={'pages:projects.inspector.manage.planning.newDependencyLogicalInfo'}
      plannedOnly={false}
      dependencyType={'logical'}
      onClose={onClose}
      {...logicalDependencyModalProps}
    />
  );
}
