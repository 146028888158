import {ReactElement, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {Dropdown, DropdownItemProps} from 'semantic-ui-react';
import {PlanningRealizationNode} from '../../../../EntityInterfaces/PlanningRealizationNode';
import {PatchPlanningDependency} from '../../../../Modules/Planning';
import {updateDependency} from '../../../../Modules/PlanningDependencies';
import OffsetModal from './OffsetModal';

interface OffsetProps {
  node: PlanningRealizationNode;
  readonly?: boolean;
  dependency: PatchPlanningDependency;
}

export default function Offset({readonly, dependency, node}: OffsetProps): ReactElement {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const [modalOpen, setModalOpen] = useState(false);

  const options = useMemo<DropdownItemProps[]>(() => {
    const values = Array.from<Array<unknown>, DropdownItemProps>({length: 31}, (_, i) => ({
      text: i - 15,
      value: i - 15,
    }));

    if (dependency.offset > 15 || dependency.offset < -15) {
      values.push({
        text: dependency.offset,
        value: dependency.offset,
      });
    }
    values.push({
      text: t('pages:projects.inspector.manage.planning.otherValue'),
      value: false,
    });
    return values;
  }, [dependency.offset, t]);

  function save(offset: number): void {
    dispatch(
      updateDependency(node.id, dependency.predecessor, {
        offset,
      }),
    );
  }

  return (
    <div className={'offset flex items-center gap-x-2'}>
      <div>
        <Dropdown
          direction={'left'}
          fluid
          selection
          options={options}
          value={dependency.offset}
          onChange={(_, {value}) => {
            if (typeof value === 'number') {
              save(value);
            } else if (value === false) {
              setModalOpen(true);
            }
          }}
          disabled={readonly}
        />
        {modalOpen && <OffsetModal setOpen={setModalOpen} onSave={save} offset={dependency.offset} />}
      </div>
      <span>
        {t('general:days', {
          count: dependency.offset,
        })}
      </span>

      {/*#region styles*/}
      {/*language=scss*/}
      <style jsx>{`
        .offset :global(.ui.selection.dropdown),
        .offset :global(.ui.selection.dropdown > .dropdown.icon) {
          min-height: 1.5em;
          padding-top: 6px;
          padding-bottom: 4px;
          padding-right: 25px;
        }

        .offset :global(.ui.selection.dropdown > .dropdown.icon) {
          padding-right: 5px;
        }

        .offset :global(.left.visible.menu) {
          width: 130px;
          border-top-width: 1px !important;
        }

        .offset :global(.upward .left.visible.menu) {
          max-height: 130px;
        }
      `}</style>
      {/*#endregion*/}
    </div>
  );
}
