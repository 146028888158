import {MinMaxPlanningDateInSubtree} from '@octaved/planning/src/EntityInterfaces/MinMaxPlanningDateInSubtrees';
import {isGrantedSelector} from '@octaved/security/src/Authorization/Authorization';
import {IconButton, Message, TWLabel} from '@octaved/ui';
import {dateFormatSelector} from '@octaved/users/src/Selectors/CurrentOrgUserSelectors';
import {Copy} from 'lucide-react';
import {ReactElement, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useNavigate, useResolvedPath} from 'react-router-dom';
import Errors from '../../Components/Feedback/Notifications/Errors';
import ValidationInputField from '../../Components/Form/Input/ValidationInputField';
import FolderForNewProject from '../../Components/ProjectFolder/FolderForNewProject';
import {Group, Project, WorkPackage} from '../../EntityInterfaces/Pid';
import {CopyPidOptions, useCopyPid} from '../../Modules/CopyPid';
import {nodeEntitySelector} from '../../Modules/Selectors/NodeSelectors';
import {useNodeAncestry} from '../../Modules/Selectors/NodeTreeSelectors';
import {formatTimeControl} from '../../Modules/TimeControlledEntity';
import {isProject} from '../../Node/NodeIdentifiers';
import {closeDialogRoute} from '../../Routing/Routes/DialogRoutes';
import {useAutoTimeControl} from './AutoTimeControl';
import CopyDetailsForm, {CopyDetailsFormProps} from './CopyDetailsForm';

interface Props<N extends Project | Group | WorkPackage> {
  minMaxPlanning: MinMaxPlanningDateInSubtree | null;
  pid: N;
  siblings: N[];
}

export default function Content<N extends Project | Group | WorkPackage>({
  minMaxPlanning,
  pid,
  siblings,
}: Props<N>): ReactElement | null {
  const {t} = useTranslation();
  const {isCopying, copy, errorFields} = useCopyPid(pid.id);
  const {ancestors} = useNodeAncestry(pid.id, true);
  const [parentNodeId, setParentNodeId] = useState(ancestors[1]!.id);
  const [copyTasks, setCopyTasks] = useState(true);
  const [showCannotCreateError, setShowCannotCreateError] = useState(false);
  const [copyAssignments, setCopyAssignments] = useState(true);
  const navigate = useNavigate();
  const resolvedPath = useResolvedPath(closeDialogRoute);
  const dateFormat = useSelector(dateFormatSelector) || '';
  const copyPidOptions: CopyPidOptions = {copyAssignments, copyTasks};

  const {autoTimeControl, autoTimeControlIsPossible, setAutoTimeControl, name, setName, pidTimeControl} =
    useAutoTimeControl(pid, siblings, copyPidOptions);
  const nodes = useSelector(nodeEntitySelector);
  const isGranted = useSelector(isGrantedSelector);

  const onSubmit = async (): Promise<void> => {
    if (isGranted('FLOW_NODE_PID_MANAGE_BASIC', parentNodeId) && nodes[parentNodeId]?.isArchived === false) {
      const newPidId = await copy(parentNodeId, name, copyPidOptions);
      if (newPidId) {
        navigate(resolvedPath.pathname.replace(pid.id, newPidId) + window.location.search);
      }
    } else {
      setShowCannotCreateError(true); //this behavior should be replaced with formik one day, see NewProjectForm
    }
  };

  const detailsProps: CopyDetailsFormProps = {
    autoTimeControl,
    autoTimeControlIsPossible,
    copyPidOptions,
    minMaxPlanning,
    pid,
    setAutoTimeControl,
    setCopyAssignments,
    setCopyTasks,
  };

  return (
    <div className={'flex flex-col gap-y-6'}>
      {showCannotCreateError && (
        <Message colorScheme={'error'}>{t('general:pid.validationErrors.cannotCreateProjectInFolder')}</Message>
      )}
      <Errors className={'w-full'} includeFields={errorFields} noMargin />
      <div className={'flex flex-col gap-y-1'}>
        <TWLabel>{t('dialogs:duplicatePid.newNameLabel')}</TWLabel>
        <div className={'flex w-full items-center gap-x-2'}>
          <ValidationInputField
            fieldName={errorFields[0]}
            value={name}
            setValue={setName}
            maxLength={191}
            style={{width: '100%'}}
          />
          {pidTimeControl && <div className={'shrink-0'}>{formatTimeControl(t, pidTimeControl, dateFormat, true)}</div>}
        </div>
      </div>

      <CopyDetailsForm {...detailsProps} />

      {isProject(pid) && (
        <FolderForNewProject
          folderId={parentNodeId}
          setFolderId={(id) => {
            setParentNodeId(id);
            setShowCannotCreateError(false);
          }}
        />
      )}

      <div className={'flex justify-center'}>
        <IconButton
          loading={isCopying}
          isDisabled={isCopying}
          icon={<Copy />}
          label={'dialogs:duplicatePid.buttonText'}
          onClick={onSubmit}
        />
      </div>
    </div>
  );
}
