import {ReactElement, ReactNode, useMemo, useRef, useState} from 'react';
import {Tooltip} from './Feedback';
import {cn} from './Hooks';

interface EllipsisTooltipProps {
  ellipsisText: ReactNode;
  tooltipContent: ReactNode;
  className?: string;
}

export function EllipsisTooltip({ellipsisText, tooltipContent, className}: EllipsisTooltipProps): ReactElement {
  const divRef = useRef<HTMLDivElement>(null);
  const [expanded, setExpanded] = useState(false);

  const hasEllipsis = useMemo(() => {
    void ellipsisText;
    if (divRef.current) {
      return (divRef.current.scrollHeight || 1) > (divRef.current.clientHeight || 0);
    }
    return false;
  }, [ellipsisText]);

  return (
    <>
      <Tooltip
        content={tooltipContent}
        toolTipTranslation={''}
        position={'bottom right'}
        disabled={!hasEllipsis || expanded}
      >
        <div
          className={cn(
            `relative truncate text-wrap break-words ${expanded ? 'line-clamp-2' : 'line-clamp-1 cursor-pointer'}`,
            className,
          )}
          ref={divRef}
          onClick={() => setExpanded(true)}
        >
          {ellipsisText}
        </div>
      </Tooltip>
    </>
  );
}
