import DialogAndDrawerHeader from '@octaved/flow/src/Components/Layout/DialogAndDrawerHeader';
import {DialogContent, DialogFrame, DialogTitle} from '@octaved/flow/src/Dialogs/DialogFrame';
import TextInput from '@octaved/ui-components/src/React/Formik/TextInput';
import VerticalForm from '@octaved/ui-components/src/React/Formik/VerticalForm';
import {useFormik} from 'formik';
import {PlusCircle} from 'lucide-react';
import {Dispatch, ReactElement, SetStateAction, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';

interface OffsetModalProps {
  setOpen: Dispatch<SetStateAction<boolean>>;
  onSave: (offset: number) => void;
  offset: number;
}
export default function OffsetModal({setOpen, onSave, offset}: OffsetModalProps): ReactElement {
  const {t} = useTranslation();

  const offsetSchema = useMemo(
    () =>
      Yup.object().shape({
        offset: Yup.number()
          .typeError({
            i18nKey: 'general:validationError.integer',
            values: {field: t('pages:projects.inspector.manage.planning.headlineOffset')},
          })
          .max(365, {
            i18nKey: 'general:validationError.max',
            values: {field: t('pages:projects.inspector.manage.planning.headlineOffset'), max: 365},
          })
          .min(-365, {
            i18nKey: 'general:validationError.min',
            values: {field: t('pages:projects.inspector.manage.planning.headlineOffset'), min: -365},
          })
          .integer({
            i18nKey: 'general:validationError.integer',
            values: {field: t('pages:projects.inspector.manage.planning.headlineOffset')},
          })
          .required({
            i18nKey: 'general:validationError.required',
            values: {field: t('pages:projects.inspector.manage.planning.headlineOffset')},
          }),
      }),
    [t],
  );

  const formik = useFormik({
    initialValues: {offset},
    onSubmit: async (values) => {
      onSave(+values.offset);
      setOpen(false);
    },
    validationSchema: offsetSchema,
  });

  return (
    <DialogFrame noScroll size={'mini'} noOwnForm>
      <DialogTitle onClose={() => setOpen(false)} growTitle>
        <DialogAndDrawerHeader header={'pages:projects.inspector.manage.planning.headlineOffset'} noPadding />
      </DialogTitle>
      <DialogContent>
        <VerticalForm submitLabel={'general:save'} submitIcon={<PlusCircle />} formik={formik}>
          <TextInput label={'pages:projects.inspector.manage.planning.daysLabel'} formik={formik} id={'offset'} />
        </VerticalForm>
      </DialogContent>
    </DialogFrame>
  );
}
