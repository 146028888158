import {EnumFlowPidBillingType} from '@octaved/env/src/dbalEnumTypes';
import {useObjectSnapshot} from '@octaved/hooks';
import {isGrantedSelector} from '@octaved/security/src/Authorization/Authorization';
import {Label} from '@octaved/ui';
import {Fragment, ReactElement, ReactNode, useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useSearchParams} from 'react-router-dom';
import {TextArea} from 'semantic-ui-react';
import VerticalForm from '../../Components/Form/FormGrids/VerticalForm';
import ValidationInputField from '../../Components/Form/Input/ValidationInputField';
import PidBillingType from '../../Components/Pid/PidBillingType';
import PidFreeOfChargeReason from '../../Components/Pid/PidFreeOfChargeReason';
import PidPrice from '../../Components/Pid/PidPrice';
import EffortHours from '../../Components/WorkPackage/EffortHours';
import WorkPackagePriceCategory from '../../Components/WorkPackage/PriceCategory/WorkPackagePriceCategory';
import {Group, Project, WorkPackage, WorkPackageCreationData} from '../../EntityInterfaces/Pid';
import {useCloseAndSelectNewNode} from '../../Hooks/CloseNodeCreationDialog';
import {useClearErrorFields, useSiblings} from '../../Hooks/CreatePid';
import {hasPidHours, hasPrice} from '../../Modules/Pid';
import {customerEntitiesSelector, isCustomerBillableSelector} from '../../Modules/Selectors/CustomerSelectors';
import {isWorkPackage} from '../../Node/NodeIdentifiers';
import {defaultBillableBillingTypeSelector} from '../../Modules/Selectors/SettingSelectors';
import {
  createWorkPackage,
  createWorkPackageEntity,
  createWorkPackageErrorFields,
  transformWorkPackageToPatchData,
} from '../../Modules/WorkPackages';

interface Props {
  parent: Project | Group;
  relative: Project | Group | WorkPackage;
  close?: () => void;
}

export default function NewWorkPackageFormBody({parent, relative, close}: Props): ReactElement {
  const [searchParams] = useSearchParams();
  const isOffer = searchParams.has('createOffer');

  const dispatch = useDispatch();
  const {snapshot, patch} = useObjectSnapshot<WorkPackageCreationData>(() => ({
    ...transformWorkPackageToPatchData({...createWorkPackageEntity(parent.flowCustomer), isOffer}),
    billingType: null,
  }));

  const siblings = useSiblings(parent);
  const closeAndSelectNewPid = useCloseAndSelectNewNode();
  const create = useCallback(async () => {
    const sortedSiblingIds = siblings.map(({id}) => id);
    const position = isWorkPackage(relative) ? sortedSiblingIds.indexOf(relative.id) : sortedSiblingIds.length - 1;
    sortedSiblingIds.splice(position + 1, 0, snapshot.id);
    if (await dispatch(createWorkPackage(snapshot, parent.id, sortedSiblingIds))) {
      if (close) {
        close();
      } else {
        closeAndSelectNewPid(snapshot.id, snapshot.nodeType);
      }
    }
  }, [close, closeAndSelectNewPid, dispatch, parent.id, relative, siblings, snapshot]);

  const customer = useSelector(customerEntitiesSelector)[snapshot.flowCustomer];
  const isCustomerBillable = useSelector(isCustomerBillableSelector)(snapshot.flowCustomer);
  const isGranted = useSelector(isGrantedSelector);
  const defaultBillableBillingType = useSelector(defaultBillableBillingTypeSelector);

  useEffect(() => {
    if (!snapshot.billingType) {
      //trac #8328: Auto-select "effort" for non-billable customers
      //trac #8329: defaultBillingType for customers
      // https://gitlab.local.hcom.de/octaved-flow/webapp/-/issues/373: default billing type in general
      const defaultType = isCustomerBillable
        ? customer?.defaultBillingType || defaultBillableBillingType
        : EnumFlowPidBillingType.VALUE_EFFORT;
      if (defaultType) {
        patch({billingType: defaultType});
      }
    }
  }, [customer?.defaultBillingType, defaultBillableBillingType, isCustomerBillable, snapshot.billingType, patch]);

  const errorFields = useClearErrorFields(snapshot.id, createWorkPackageErrorFields);

  const formFields: ReactNode[] = [
    <Fragment key={'name'}>
      <Label token={'dialogs:createWorkpackage.name'} />
      <ValidationInputField
        autoFocus
        fieldName={`name_${snapshot.id}`}
        setValue={(name) => patch({name})}
        value={snapshot.name}
      />
    </Fragment>,
    <Fragment key={'shortDescription'}>
      <Label token={'dialogs:createWorkpackage.shortDescription'} />
      <TextArea onChange={(_e, {value}) => patch({description: value as string})} value={snapshot.description} />
    </Fragment>,
  ];

  if (isGranted('FLOW_NODE_MANAGE_PRICES', parent.id)) {
    formFields.push(
      <Fragment key={'billing'}>
        <PidBillingType
          createLabel={(translatable) => <Label token={translatable} />}
          upward
          pidData={snapshot}
          updatePid={patch}
          editAble
          data-selenium-id={'createWorkpackagePidBillingType'}
        />
      </Fragment>,
    );

    formFields.push(
      <WorkPackagePriceCategory
        key={'priceCategory'}
        label={<Label token={'dialogs:createWorkpackage.priceCategory'} />}
        patch={patch}
        snapshot={snapshot}
        isNewWorkPackage
        editAble
        upward
      />,
    );

    if (hasPrice(snapshot)) {
      formFields.push(
        <Fragment key={'price'}>
          <Label token={'dialogs:createWorkpackage.price'} />
          <PidPrice
            price={snapshot.fixedPrice}
            setPrice={(fixedPrice) => patch({fixedPrice})}
            data-selenium-id={'createWorkpackagePriceInput'}
          />
        </Fragment>,
      );
    }

    if (snapshot.billingType === EnumFlowPidBillingType.VALUE_FREE_OF_CHARGE) {
      formFields.push(
        <Fragment key={'reason'}>
          <Label token={'sideSteps:projectNodeDetails.settings.timeAndFinancial.reason'} />
          <PidFreeOfChargeReason patch={patch} pid={snapshot} />
        </Fragment>,
      );
    }

    if (hasPidHours(snapshot)) {
      formFields.push(
        <Fragment key={'hours'}>
          <Label token={'dialogs:createWorkpackage.hours'} />
          <EffortHours
            id={snapshot.id}
            patch={patch}
            snapshot={snapshot}
            data-selenium-id={'createWorkpackageHoursInput'}
          />
        </Fragment>,
      );
    }
  }

  return (
    <VerticalForm onSubmit={create} submitLabel={'general:create'} includeErrorFields={errorFields}>
      {formFields}
    </VerticalForm>
  );
}
