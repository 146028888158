import {Check} from 'lucide-react';
import {Selector} from 'react-redux';
import {createSelector} from 'reselect';
import {FlowState} from '../../../../Modules/State';
import {ThunkAct} from '../../../../Store/Thunk';
import {DropdownPopupItem, DropdownPopupItemOptions, DropdownPopupItemType} from './Types';

export function createCheckOption(
  selector: Selector<FlowState, boolean>,
  patch: (check: boolean) => void | ThunkAct<void>,
  token: string,
  additionalAttributes?: Partial<
    Omit<DropdownPopupItem, 'type' | 'iconColor' | 'icon' | 'iconSize' | 'onClick' | 'token'>
  >,
): DropdownPopupItemOptions {
  const iconColor = createSelector(selector, (current) => (current ? 'green' : 'white'));
  return {
    iconColor,
    token,
    icon: <Check />,
    iconSize: 'small',
    onClick: () => (dispatch, getState) => {
      const result = patch(!selector(getState()));
      if (result) {
        dispatch(result);
      }
    },
    type: DropdownPopupItemType.item,
    ...additionalAttributes,
  };
}
