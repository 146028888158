import {IconButton} from '@octaved/ui';
import {Airplay, MoreHorizontal} from 'lucide-react';
import {ReactElement, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {useInspectorSubMenu} from '../../../Drawer/StandardInspector/InspectorSubMenu';
import {Pid} from '../../../EntityInterfaces/Pid';
import {generateStandardInspectorRoute} from '../../../Routing/Routes/Inspectors/StandardInspector';
import DropdownPopup from '../../Form/Dropdown/DropdownPopup';
import {DropdownPopupItemOptions, DropdownPopupItemType} from '../../Form/Dropdown/DropdownPopup/Types';

interface Props {
  node?: Pid;
}

export default function WorkPackageTileMenu({node}: Props): ReactElement {
  const inspectorMenu = useInspectorSubMenu(node);
  const navigate = useNavigate();

  const items = useMemo<DropdownPopupItemOptions[]>(() => {
    const menu: DropdownPopupItemOptions[] = [
      {
        icon: <Airplay rotate={'90deg'} />,
        iconColor: 'darkGrey',
        onMouseDown: () => navigate(generateStandardInspectorRoute(node?.id)),
        token: 'projectsTree:openWorkPackageDetails',
        type: DropdownPopupItemType.item,
      },
      ...inspectorMenu,
    ];

    return menu;
  }, [inspectorMenu, navigate, node?.id]);

  return (
    <DropdownPopup items={items} closeOnSelect position={'bottom left'}>
      <IconButton variant={'ghost'} icon={<MoreHorizontal />} className={'showOnHover'} size={'xs'} />
    </DropdownPopup>
  );
}
