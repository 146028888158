import * as DialogPrimitive from '@radix-ui/react-dialog';
import {cva} from 'class-variance-authority';
import {X} from 'lucide-react';
import * as React from 'react';
import {cn} from './Hooks';

const Dialog = DialogPrimitive.Root;

const DialogTrigger = DialogPrimitive.Trigger;

const DialogPortal = DialogPrimitive.Portal;

const DialogClose = DialogPrimitive.Close;

const DialogOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({className, ...props}, ref) => (
  <>
    <DialogPrimitive.Overlay ref={ref} className={cn(className)} {...props} />
    <div className={'absolute left-0 top-0 z-30 h-full w-full bg-black/15 duration-200 animate-in fade-in-0'}></div>
  </>
));
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;

const dialogContenVariants = cva('', {
  defaultVariants: {
    size: 'md',
  },
  variants: {
    size: {
      lg: 'max-w-3xl',
      md: 'max-w-2xl',
    },
  },
});

const preventDefault = (event: Event): void => {
  event.preventDefault();
};

const DialogContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content> & {
    size: 'md' | 'lg';
  }
>(({className, children, size, ...props}, ref) => (
  <DialogPortal>
    <DialogOverlay />
    <DialogPrimitive.Content
      ref={ref}
      aria-describedby={undefined}
      className={cn(
        'fixed left-[50%] top-[50%] z-50 grid w-full max-w-3xl translate-x-[-50%] translate-y-[-50%] gap-4 rounded-md border bg-white px-6 pb-4 pt-4 shadow-old duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%]',
        className,
        dialogContenVariants({size}),
      )}
      onEscapeKeyDown={preventDefault}
      onPointerDownOutside={preventDefault}
      onOpenAutoFocus={preventDefault}
      {...props}
    >
      {children}
    </DialogPrimitive.Content>
  </DialogPortal>
));
DialogContent.displayName = DialogPrimitive.Content.displayName;

interface DialogHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  noClose?: boolean;
}

const DialogHeader = ({className, children, noClose, ...props}: DialogHeaderProps): JSX.Element => (
  <div className={cn('flex justify-between text-left')} {...props}>
    <div className={cn('mr-6', className)}>{children}</div>
    {!noClose && (
      <DialogPrimitive.Close
        className={
          'ring-offset-background focus:ring-ring data-[state=open]:bg-accent data-[state=open]:text-muted-foreground rounded-sm focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:pointer-events-none'
        }
      >
        <X className={'h-4 w-4'} />
        <span className={'sr-only'}>Close</span>
      </DialogPrimitive.Close>
    )}
  </div>
);
DialogHeader.displayName = 'DialogHeader';

const DialogBody = ({className, ...props}: React.HTMLAttributes<HTMLDivElement>): JSX.Element => (
  <div className={cn('flex flex-col justify-between gap-y-4', className)} {...props} />
);
DialogBody.displayName = 'DialogBody';

const DialogFooter = ({className, ...props}: React.HTMLAttributes<HTMLDivElement>): JSX.Element => (
  <div className={cn('flex flex-row items-center justify-center gap-x-2', className)} {...props} />
);
DialogFooter.displayName = 'DialogFooter';

const DialogTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({className, ...props}, ref) => (
  <DialogPrimitive.Title
    ref={ref}
    className={cn('m-0 text-lg font-medium leading-none tracking-tight text-slate-600', className)}
    {...props}
  />
));
DialogTitle.displayName = DialogPrimitive.Title.displayName;

const DialogDescription = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({className, ...props}, ref) => (
  <DialogPrimitive.Description ref={ref} className={cn('text-muted-foreground text-sm', className)} {...props} />
));
DialogDescription.displayName = DialogPrimitive.Description.displayName;

export {
  Dialog as TWDialog,
  DialogPortal as TWDialogPortal,
  DialogOverlay as TWDialogOverlay,
  DialogClose as TWDialogClose,
  DialogTrigger as TWDialogTrigger,
  DialogContent as TWDialogContent,
  DialogHeader as TWDialogHeader,
  DialogBody as TWDialogBody,
  DialogFooter as TWDialogFooter,
  DialogTitle as TWDialogTitle,
  DialogDescription as TWDialogDescription,
};
