import {Uuid} from '@octaved/typescript/src/lib';
import {PlusCircle} from 'lucide-react';
import {useCallback, useMemo} from 'react';
import {useDispatch} from 'react-redux';
import {patchTaskListUiState} from '../../../Modules/Ui/TaskList';
import {DropdownPopupItemOptions, DropdownPopupItemType} from '../../Form/Dropdown/DropdownPopup/Types';
import {useTaskGroupContext} from './TaskGroupContext';

function useAddNewTask(parentNodeId: Uuid): () => void {
  const {focusNewTask} = useTaskGroupContext();
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(patchTaskListUiState({closedGroups: {[parentNodeId]: false}}));
    setTimeout(() => {
      if (focusNewTask.current) {
        focusNewTask.current();
      }
    }, 10);
  }, [dispatch, focusNewTask, parentNodeId]);
}

export function useNewTaskOption(parentNodeId: Uuid): DropdownPopupItemOptions {
  const onClick = useAddNewTask(parentNodeId);
  return useMemo(
    () => ({
      onClick,
      icon: <PlusCircle />,
      iconColor: 'darkGrey',
      token: 'taskList:groupMenu.newTaskLabel',
      type: DropdownPopupItemType.item,
    }),
    [onClick],
  );
}

export function useNewTaskSectionOption(parentNodeId: Uuid): DropdownPopupItemOptions {
  const {focusNewTaskSection} = useTaskGroupContext();
  return useMemo(
    () => ({
      icon: <PlusCircle />,
      iconColor: 'darkGrey',
      onClick: () => {
        return (dispatch) => {
          dispatch(patchTaskListUiState({closedGroups: {[parentNodeId]: false}}));
          setTimeout(() => {
            if (focusNewTaskSection.current) {
              focusNewTaskSection.current();
            }
          }, 10);
        };
      },
      token: 'taskList:groupMenu.newTaskSectionLabel',
      type: DropdownPopupItemType.item,
    }),
    [focusNewTaskSection, parentNodeId],
  );
}
