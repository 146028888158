import {isDevLocal} from '@octaved/env/src/Environment';
import {PatchableState, usePatchableState} from '@octaved/hooks/src/PatchableState';
import {useIsGranted} from '@octaved/security/src/Authorization/Authorization';
import {boolFilter} from '@octaved/utilities';
import {Airplay, Copy, Link, Shuffle, Trash2} from 'lucide-react';
import {ReactElement} from 'react';
import {useNavigate} from 'react-router-dom';
import {NodeSearchCondition} from '../../../EntityInterfaces/NodeSearch';
import {WorkPackage} from '../../../EntityInterfaces/Pid';
import {SubWorkPackage} from '../../../EntityInterfaces/SubWorkPackage';
import {useNodeAncestry} from '../../../Modules/Selectors/NodeTreeSelectors';
import {FlowState} from '../../../Modules/State';
import {patchWorkPackage} from '../../../Modules/WorkPackages';
import {isWorkPackage} from '../../../Node/NodeIdentifiers';
import {getRestoreNodesFromTrashRoute} from '../../../Routing/Routes/Dialogs';
import {useGenerateDynamicLink} from '../../DynamicLink';
import {appendDropdownOptions} from '../../Form/Dropdown/DropdownPopup/AppendOptions';
import {DropdownPopupItemOptions, DropdownPopupItemType} from '../../Form/Dropdown/DropdownPopup/Types';
import {useStartTimeTrackingOption} from '../../TimeRecord/StartTimeTrackingOption';
import CopyWorkPackageTasks from '../CopyWorkPackageTasks/CopyWorkPackageTasks';
import MoveWorkPackageTasks from '../MoveWorkPackageTasks/MoveWorkPackageTasks';
import {useTaskListContext} from '../TaskListContext';
import {useOnHoverOpenGroupDropRef} from '../TaskListForNode/DragAndDrop/DragItems';
import TaskListForNode from '../TaskListForNode/TaskListForNode';
import TaskListReadonlyContext, {useTaskListReadonlyContext} from '../TaskListReadonlyContext';
import {useNewTaskOption, useNewTaskSectionOption} from './Options';
import {useTaskGroupContext} from './TaskGroupContext';
import WpOrSwpTaskGroup from './WpOrSwpTaskGroup';

interface Props {
  node: WorkPackage | SubWorkPackage;
  select?: () => void;
  selected?: boolean;
  taskFilterQuerySelector?: (state: FlowState) => NodeSearchCondition | null;
}

interface State {
  openCopyTasks: boolean;
  openMoveWorkPackages: boolean;
}

export function createUseMenuOptions([, patch]: PatchableState<State>): () => DropdownPopupItemOptions[] {
  return () => {
    const {
      allowCreationOfTasks,
      allowCreationOfTaskSections,
      hideTrackTime,
      showCopyTasks,
      showMoveTasks,
      showOpenWorkPackageInspector,
    } = useTaskListContext();
    const {id} = useTaskGroupContext();
    const {readonly} = useTaskListReadonlyContext();
    const canManage = useIsGranted('FLOW_NODE_TASK_MANAGE_BASIC', id) && !readonly;
    const canRestoreTrash = useIsGranted('FLOW_NODE_TASK_RESTORE_FROM_TRASH', id) && !readonly;
    const newTaskOption = useNewTaskOption(id);
    const newTaskSectionOption = useNewTaskSectionOption(id);
    const options: DropdownPopupItemOptions[] = [];
    const {workPackage, subWorkPackage} = useNodeAncestry(id, true);
    const wpOrSwp = subWorkPackage ?? workPackage;
    const linkGenerator = useGenerateDynamicLink();
    const navigate = useNavigate();
    const timeTrackingOption = useStartTimeTrackingOption('', wpOrSwp?.id, hideTrackTime);

    if (showOpenWorkPackageInspector) {
      appendDropdownOptions(
        options,
        boolFilter([
          {
            hidden: !id,
            icon: <Airplay rotate={'90deg'} />,
            iconColor: 'darkGrey',
            link: id,
            token: subWorkPackage
              ? 'pages:timeRecordings.all.tables.rowMenu.openSubWorkPackage'
              : 'pages:timeRecordings.all.tables.rowMenu.openWorkPackage',
            type: DropdownPopupItemType.itemWithLink,
          },
        ]),
      );
    }

    appendDropdownOptions(
      options,
      boolFilter([
        canManage && allowCreationOfTasks && newTaskOption,
        canManage && allowCreationOfTaskSections && newTaskSectionOption,
        canRestoreTrash &&
          isDevLocal && {
            icon: <Trash2 />,
            iconColor: 'darkGrey',
            onMouseDown: () => navigate(linkGenerator('dialog', getRestoreNodesFromTrashRoute(id, 'tasks'))),
            token: 'pages:projects.inspector.header.trash',
            type: DropdownPopupItemType.item,
          },
      ]),
    );

    if (!timeTrackingOption.hidden && wpOrSwp && !wpOrSwp.isLocked && !wpOrSwp.isArchived) {
      appendDropdownOptions(options, [timeTrackingOption]);
    }

    appendDropdownOptions(
      options,
      boolFilter([
        canManage &&
          showMoveTasks && {
            icon: <Shuffle />,
            iconColor: 'darkGrey',
            onClick: () => patch({openMoveWorkPackages: true}),
            token: 'taskList:groupMenu.moveTasksToOtherWorkPackage',
            type: DropdownPopupItemType.item,
          },
        canManage &&
          showCopyTasks && {
            icon: <Copy />,
            iconColor: 'darkGrey',
            onClick: () => patch({openCopyTasks: true}),
            token: 'taskList:groupMenu.copyTasksFromOtherWorkPackage',
            type: DropdownPopupItemType.item,
          },
      ]),
    );

    if (!subWorkPackage && workPackage) {
      appendDropdownOptions(
        options,
        boolFilter([
          canManage && {
            icon: <Link />,
            iconColor: 'darkGrey',
            iconSize: 'small',
            onClick: () => patchWorkPackage(workPackage.id, {isAutoChainActive: !workPackage.isAutoChainActive}),
            token: 'taskList:groupMenu.autoChain',
            type: DropdownPopupItemType.item,
          },
        ]),
      );
    }

    return options;
  };
}

export default function WpOrSwpGroup({node, select, selected, taskFilterQuerySelector}: Props): ReactElement {
  const patchableState = usePatchableState({openCopyTasks: false, openMoveWorkPackages: false});
  const [state, patch] = patchableState;
  const canManageTasks = useIsGranted('FLOW_NODE_TASK_MANAGE_BASIC', node.id) && !node.isArchived;
  const {readonly} = useTaskListReadonlyContext();
  const openGroupDropRef = useOnHoverOpenGroupDropRef(node.id, readonly || !canManageTasks);
  return (
    <TaskListReadonlyContext canManageTasks={canManageTasks} readonly={readonly}>
      <WpOrSwpTaskGroup
        dropRef={openGroupDropRef}
        node={node}
        select={select}
        selected={selected}
        useMenuOptions={createUseMenuOptions(patchableState)}
      >
        <TaskListForNode nodeId={node.id} taskFilterQuerySelector={taskFilterQuerySelector} />
      </WpOrSwpTaskGroup>

      {isWorkPackage(node) && (
        <CopyWorkPackageTasks
          workPackageId={node.id}
          open={state.openCopyTasks}
          setOpen={(open) => patch({openCopyTasks: open})}
        />
      )}

      {isWorkPackage(node) && (
        <MoveWorkPackageTasks
          workPackageId={node.id}
          open={state.openMoveWorkPackages}
          setOpen={(open) => patch({openMoveWorkPackages: open})}
        />
      )}
    </TaskListReadonlyContext>
  );
}
