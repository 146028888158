import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import NodeToggleDone from '@octaved/node-details/src/Components/NodeToggleDone';
import TaskToggleDone from '@octaved/node-details/src/Components/TaskToggleDone';
import {GroupLargeViewPaths} from '@octaved/node-details/src/InspectorView/Config/GroupTabs';
import {ProjectLargeViewPaths} from '@octaved/node-details/src/InspectorView/Config/ProjectTabs';
import {TaskLargeViewPaths} from '@octaved/node-details/src/InspectorView/Config/TaskTabs';
import {WorkPackageLargeViewPaths} from '@octaved/node-details/src/InspectorView/Config/WorkPackageTabs';
import {useIsPlanningPage} from '@octaved/planning/src/Hooks/UseIsPlanningPage';
import {isPlanningDateInspectorContext} from '@octaved/planning/src/Inspector/PlanningDateInspectorContext';
import PlanningDateHeader from '@octaved/planning/src/Pages/Gantt/Components/Inspector/PlanningDateHeader';
import {simulationModeActiveSelector} from '@octaved/planning/src/Selectors/UiSelectors';
import {cn, EllipsisTooltip, IconButton, Tooltip} from '@octaved/ui';
import {ArrowLeft, Pin} from 'lucide-react';
import {ComponentType, lazy, ReactElement, ReactNode, useContext} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import NodeName from '../../Components/Node/NodeName';
import PidTypeName from '../../Components/Node/NodeTypeName';
import StringPath from '../../Components/Node/StringPath';
import FavoriteStar from '../../Components/Pid/FavoriteStar';
import MarkTaskFinished from '../../Components/Task/MarkTaskFinished';
import InspectorWorkflowStatus from '../../Components/WorkPackage/WorkflowStatus/InspectorWorkflowStatus';
import Close from '../../Dialogs/Components/Close';
import {
  isPid,
  isPidOrSubWOrkPackage,
  isProject,
  isSubWorkPackage,
  isTask,
  isWorkPackage,
} from '../../Node/NodeIdentifiers';
import {drawerPinnedSelector} from '../../Modules/Selectors/UiSelectors';
import {setUiState} from '../../Modules/Ui';
import Header from '../../Pages/Projects/Pages/TimesheetsExternal/Inspector/Header';
import {LargeViewTargetPage} from '../../Routing/Routes/Node';
import {inspectorContext, isTimesheetInspectorContext} from '../InspectorContext/InspectorContext';
import {DEFAULT_INSPECTOR_WIDTH} from './StandardInspectorPage';
import {useTranslation} from 'react-i18next';

const PidHeader = lazy(() =>
  import('@octaved/node-details/src/InspectorView/Components/NodeHeader').then((module) => {
    return {default: module.NodeHeader};
  }),
);

const taskTabToNewInspectorTab: Record<string, TaskLargeViewPaths> = {
  editTask: 'overview',
  history: 'details/history',
  planning: 'details/planning',
  specialPlanning: 'overview',
};

const workPackageTabToNewInspectorTab: Record<string, WorkPackageLargeViewPaths | 'board' | 'tasks'> = {
  advanced: 'details/advanced',
  billings: 'details/settings',
  board: 'board',
  general: 'overview',
  guestUser: 'details/guests',
  planning: 'details/planning',
  responsibility: 'details/responsibility',
  settings: 'details/settings',
  specialPlanning: 'planning',
  tasks: 'tasks',
  timeAndMoney: 'details/timeAndMoney',
};

const groupTabToNewInspectorTab: Record<string, GroupLargeViewPaths | 'board' | 'tasks' | 'planning'> = {
  advanced: 'details/advanced',
  billings: 'details/settings',
  board: 'board',
  general: 'overview',
  guestUser: 'details/guests',
  planning: 'details/planning',
  responsibility: 'details/responsibility',
  settings: 'details/settings',
  specialPlanning: 'planning',
  status: 'controlling/overview',
  tasks: 'tasks',
};

const projectTabToNewInspectorTab: Record<string, ProjectLargeViewPaths | 'board' | 'tasks' | 'planning'> = {
  advanced: 'details/advanced',
  billings: 'details/settings',
  board: 'board',
  general: 'overview',
  guestUser: 'details/guests',
  planning: 'details/planning',
  responsibility: 'details/responsibility',
  settings: 'details/settings',
  specialPlanning: 'planning',
  status: 'controlling/overview',
  tasks: 'tasks',
};

interface Props {
  actionMenu?: ComponentType;
  headerTitle?: ReactNode;
  canMarkAsDone: boolean;
}

export default function StandardInspectorHeader({
  actionMenu: ActionMenu,
  headerTitle,
  canMarkAsDone,
}: Props): ReactElement {
  const isInSimulationMode = useSelector(simulationModeActiveSelector);
  const context = useContext(inspectorContext);
  const back = context?.back;
  const drawerPinned = useSelector(drawerPinnedSelector);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const close = context?.close;
  const entity = context?.selectedEntity;
  const location = useLocation();
  const currentTab = location.pathname.split('/').pop();
  const isPlanningPage = useIsPlanningPage();

  if (isPidOrSubWOrkPackage(entity) || isTask(entity)) {
    let tabs = undefined;
    if (entity.nodeType === EnumFlowNodeType.VALUE_GROUP) {
      tabs = groupTabToNewInspectorTab;
    } else if (entity.nodeType === EnumFlowNodeType.VALUE_PROJECT) {
      tabs = projectTabToNewInspectorTab;
    } else if (entity.nodeType === EnumFlowNodeType.VALUE_WORK_PACKAGE) {
      tabs = workPackageTabToNewInspectorTab;
    } else if (entity.nodeType === EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE) {
      tabs = workPackageTabToNewInspectorTab;
    } else if (entity.nodeType === EnumFlowNodeType.VALUE_TASK) {
      tabs = taskTabToNewInspectorTab;
    }
    const sharedTab: LargeViewTargetPage | undefined = tabs && tabs[currentTab as string];
    return (
      <PidHeader
        close={() => close && close()}
        node={entity}
        shareTab={sharedTab}
        additionalContent={
          isWorkPackage(entity) || isSubWorkPackage(entity) || isTask(entity) ? (
            <div className={'flex px-5 pb-4'}>
              {(isWorkPackage(entity) || isSubWorkPackage(entity)) && <NodeToggleDone node={entity} />}
              {isTask(entity) && <TaskToggleDone taskId={entity.id} />}
            </div>
          ) : undefined
        }
      />
    );
  }

  return (
    <div className={'flex max-w-[550px] flex-col overflow-hidden'}>
      <div
        className={cn('flex items-center justify-between pl-5 pr-2 pt-2', !(isPid(entity) || isTask(entity)) && 'pb-2')}
      >
        <div className={'flex items-center gap-x-3'}>
          {back && <IconButton variant={'ghost'} icon={<ArrowLeft />} onClick={back} size={'sm'} />}

          {/** Custom Header */}
          {isTimesheetInspectorContext(context) && <Header userId={context.selectedId} />}
          {isPlanningDateInspectorContext(context) && <PlanningDateHeader />}
          {headerTitle}

          {(isPid(entity) || isTask(entity)) && (
            <div
              className={'flex'}
              style={{maxWidth: `calc(${DEFAULT_INSPECTOR_WIDTH} - 200px - ${back ? '30px' : '0px'})`}}
            >
              <div className={'mb-0 text-base font-medium text-slate-500'}>
                <PidTypeName type={entity.nodeType} nodeId={entity.id} />:
              </div>
              &nbsp;
              <EllipsisTooltip
                ellipsisText={<NodeName nodeId={entity.id} notSearchable />}
                tooltipContent={t('tooltips:general.expandEllipsis')}
              />
            </div>
          )}
        </div>

        <div className={'flex gap-x-3'}>
          {ActionMenu && <ActionMenu />}
          <div>
            <Tooltip toolTipTranslation={drawerPinned ? 'drawer:unpin' : 'drawer:pin'} position={'bottom right'}>
              <IconButton
                variant={'ghost'}
                size={'sm'}
                icon={<Pin />}
                iconColor={drawerPinned ? 'blue' : 'darkGrey'}
                isUnderlined={drawerPinned}
                isActive={drawerPinned}
                onClick={() => {
                  dispatch(setUiState({drawerPinned: !drawerPinned}));
                }}
              />
            </Tooltip>
          </div>
          {(!isPlanningPage || !isInSimulationMode) && (isWorkPackage(entity) || isProject(entity)) && (
            <FavoriteStar
              nodeId={entity.id}
              toolTipPosition={'bottom right'}
              toolTipOverride={(isWorkPackage(entity) && 'drawer:favoriteButtonTooltip') || undefined}
            />
          )}
          {close && <Close close={close} />}
        </div>
      </div>

      {(isPid(entity) || isTask(entity)) && (
        <div className={'px-5 pb-2 pt-1 text-sm'}>
          <StringPath nodeId={entity.id} excludeSelf includeCustomer />
        </div>
      )}

      {canMarkAsDone && isWorkPackage(entity) && <InspectorWorkflowStatus workPackageId={entity.id} />}
      {canMarkAsDone && isTask(entity) && <MarkTaskFinished taskId={entity.id} />}
    </div>
  );
}
