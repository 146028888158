import {Group} from '@octaved/flow/src/EntityInterfaces/Pid';
import {getNodeAncestrySelector} from '@octaved/flow/src/Modules/Selectors/NodeTreeSelectors';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {ReactElement} from 'react';
import {useSelector} from 'react-redux';
import Section from '../../../../Section';
import Customer from '../../../Common/Customer';

interface ProjectCustomerSectionProps {
  node: Group;
}

export default function GroupCustomerSection({node}: ProjectCustomerSectionProps): ReactElement | null {
  const project = useSelector((state: FlowState) => getNodeAncestrySelector(state)(node.id, true).project);
  if (!project) {
    throw Error('Project not found for group');
  }
  return <Customer frame={Section} customerId={project.flowCustomer} customerLocationId={project.customerLocation} />;
}
